import React, { useMemo, useRef, useState } from 'react';
import { uniqBy } from 'lodash';

import {
  Select,
  SELECT_PAGE_SIZE_BIG,
  useSelectAsync,
} from '@npm/core/ui/components/atoms/Select';
import { onPopupScroll } from '@npm/core/ui/components/atoms/Select/Select.utils';
import { CompanySearchLogo } from '@npm/core/ui/components/molecules/CompanySearchLogo';
import {
  type IssuerEntityAggregate,
  type Watchlist,
  useIssuerEntityIndexInfinite,
} from '@npm/data-access';

import { useRequestCompanyCoverage } from '../../company/RequestCompanyCoverage';

import { WatchlistCompanyTag } from './WatchlistCompanyTag';

import * as S from './WatchlistInput.styles';

type Props = {
  onFocus?: () => void;
  value?: string[];
  onChange?: (companies: string[]) => void;
  closeOnceSelected?: boolean;
  watchlists?: Watchlist[];
};

export const WatchlistInput = ({
  value,
  onChange,
  onFocus,
  closeOnceSelected = true,
  watchlists,
}: Props) => {
  const [{ searchTerm }, selectAsyncProps] = useSelectAsync();
  const resetSearchTerm = () => selectAsyncProps.onSearch('');

  const { requestCoverageButtonComponent, requestCoverageModalComponent } =
    useRequestCompanyCoverage({ searchTerm });

  const selectedCompaniesMapCache = useRef<
    Record<string, IssuerEntityAggregate>
  >({});

  const [open, setOpen] = useState(false);
  const wasClosedOnce = useRef(false);

  const {
    data,
    isLoading,
    error,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useIssuerEntityIndexInfinite(
    {
      size: SELECT_PAGE_SIZE_BIG,
      search: searchTerm,
    },
    { onError: Select.onError }
  );

  const options = useMemo(() => {
    if (!data) return [];

    const merged = data?.pages?.reduce(
      (mergedArray, page) => [...mergedArray, ...page.issuer_entities],
      []
    );

    return uniqBy(
      merged.map(item => ({
        label: (
          <CompanySearchLogo
            url={item.logo_url}
            size="sm"
            name={item.name}
            title={item.name}
          />
        ),
        value: item.id.toString(),
        item,
      })),
      'value'
    );
  }, [data]);

  return (
    <>
      <S.Select
        open={open}
        onDropdownVisibleChange={visible => setOpen(visible)}
        variant="search"
        mode={'multiple'}
        tagRender={({ value, onClose }) => {
          const item = selectedCompaniesMapCache.current[value];
          const isWatchlistRemovable = watchlists?.find(
            watchlist => watchlist?.issuer_entity_id === Number(value)
          )?.user_can_delete_company;

          return (
            <WatchlistCompanyTag
              companyId={value}
              company={item}
              onClose={onClose}
              disableRemove={isWatchlistRemovable === false}
            />
          );
        }}
        maxTagCount={999}
        onPopupScroll={e =>
          onPopupScroll(e, hasNextPage && !isFetchingNextPage && fetchNextPage)
        }
        placeholder={'Select...'}
        error={error}
        loading={isLoading}
        infiniteLoading={isFetchingNextPage}
        value={value}
        onChange={(values: string[]) => {
          if (closeOnceSelected && !wasClosedOnce.current) {
            wasClosedOnce.current = true;
            setOpen(false);
          }

          values.forEach(value => {
            if (!selectedCompaniesMapCache.current[value]) {
              const company = options.find(
                option => option.value === value
              )?.item;
              if (company) {
                selectedCompaniesMapCache.current[value] = company;
              }
            }
          });

          onChange(values);
          resetSearchTerm();
        }}
        onBlur={resetSearchTerm}
        autoClearSearchValue={true}
        getPopupContainer={() => document.body}
        onFocus={onFocus}
        {...selectAsyncProps}
      >
        {options.map(option => (
          <Select.Option
            key={option.value}
            value={option.value}
            item={option.item}
          >
            {option.label}
          </Select.Option>
        ))}
        {!isLoading && !!searchTerm.length && (
          <Select.Option disabled>
            {requestCoverageButtonComponent}
          </Select.Option>
        )}
      </S.Select>
      {requestCoverageModalComponent}
    </>
  );
};
