import React from 'react';

import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { useWizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.store';
import { type WizardStore } from '@npm/core/ui/components/organisms/WizardNew/Wizard.types';

import { useUserContextStore } from '../../auth/user/context';
import { type PostOnboardingContext } from '../PostOnboarding.types';

import { IconBox } from './IconBox';

type Props = {
  modalProps: React.ComponentProps<typeof Modal>;
  accountId?: number;
};

const getFooterButtonsProps = (
  hasLivePrograms: boolean,
  continueOnboarding: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void,
  goToLivePrograms: () => void,
  hasAccessToMarketplace: boolean
): Partial<Props['modalProps']> => {
  const continueButtonText = `Continue with ${
    hasAccessToMarketplace ? 'Marketplace Onboarding' : 'Accreditation'
  }`;

  if (hasLivePrograms) {
    return {
      okText: 'Continue to Live Programs',
      okButtonProps: {
        icon: <Icon name="external-link" size="xs" />,
      },
      onOk: goToLivePrograms,
      cancelText: continueButtonText,
      onCancel: continueOnboarding,
    };
  } else {
    return {
      okText: continueButtonText,
      onOk: continueOnboarding,
      okButtonProps: {
        icon: <Icon name="external-link" size="xs" />,
      },
    };
  }
};

export const AMLKYCCompletedModal = ({ modalProps }: Props) => {
  const history = useHistory();

  const hasLivePrograms = useWizardStore(
    (s: WizardStore<PostOnboardingContext>) => s.data.isInvitedToLivePrograms
  );
  const { isEntityNpmsInvestor, isIndividualNpmsInvestor } =
    useUserContextStore(s => ({
      isEntityNpmsInvestor: s.isEntityNpmsInvestor,
      isIndividualNpmsInvestor: s.isIndividualNpmsInvestor,
    }));
  const hasAccessToMarketplace =
    isEntityNpmsInvestor || isIndividualNpmsInvestor;

  const goToLivePrograms = () => {
    history.push('/investor-workstation/programs/live-programs');
  };

  return (
    <Modal
      {...modalProps}
      title="Congratulations!"
      size="md"
      {...getFooterButtonsProps(
        hasLivePrograms,
        modalProps.onCancel,
        goToLivePrograms,
        hasAccessToMarketplace
      )}
    >
      <Flex>
        <IconBox />
        <Text size="sm">
          {!hasAccessToMarketplace
            ? `Congratulations on completing AML KYC. If you are purchasing shares, please proceed to accreditation. Otherwise, click 'Continue to Live Programs.'`
            : `Your AML/KYC submission has been received and is under review. ${
                hasLivePrograms
                  ? `Click
            on "Continue to Live Programs" to participate in an event. You can
            also choose to continue with Marketplace onboarding to unlock full
            marketplace capabilities via the SecondMarket Trading platform.`
                  : `Click on "Continue with Marketplace Onboarding" to unlock full marketplace capabilities via the SecondMarket Trading platform.`
              }`}
        </Text>
      </Flex>
    </Modal>
  );
};
