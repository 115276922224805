/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { EventsCapTableStatisticShow } from '../model';
// @ts-ignore
import { MatchAggregate } from '../model';
// @ts-ignore
import { MatchDesignationCreateRequestContract } from '../model';
// @ts-ignore
import { MatchIndex } from '../model';
// @ts-ignore
import { MatchRofrAssignCreateRequestContract } from '../model';
// @ts-ignore
import { MatchStatistic } from '../model';
// @ts-ignore
import { MatchUpdateRequestContract } from '../model';
/**
 * MatchApi - axios parameter creator
 * @export
 */
export const MatchApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [startDate] 
         * @param {Array<string>} [matchState] 
         * @param {boolean} [findRequiresAttention] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityMatchIndex: async (id: number, page?: number, size?: number, startDate?: string, matchState?: Array<string>, findRequiresAttention?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('issuerEntityMatchIndex', 'id', id)
            const localVarPath = changeUrl(`/api/issuer-entities/{id}/matches`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = startDate;
            }

            if (matchState) {
                localVarQueryParameter['match_state'] = matchState.join(COLLECTION_FORMATS.csv);
            }

            if (findRequiresAttention !== undefined) {
                localVarQueryParameter['find_requires_attention'] = findRequiresAttention;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary allocates match
         * @param {MatchDesignationCreateRequestContract} matchDesignationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchDesignationCreate: async (matchDesignationCreateRequestContract: MatchDesignationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchDesignationCreateRequestContract' is not null or undefined
            assertParamExists('matchDesignationCreate', 'matchDesignationCreateRequestContract', matchDesignationCreateRequestContract)
            const localVarPath = changeUrl(`/api/match-designation`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matchDesignationCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves designated matches of parent match
         * @param {number} matchId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchDesignationShow: async (matchId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchId' is not null or undefined
            assertParamExists('matchDesignationShow', 'matchId', matchId)
            const localVarPath = changeUrl(`/api/match-designation/{match_id}`)
                .replace(`{${"match_id"}}`, encodeURIComponent(String(matchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {string} [assetType] 
         * @param {Array<string>} [matchState] 
         * @param {string} [transactionDate] 
         * @param {number} [accountId] 
         * @param {Array<number>} [orderItemId] 
         * @param {boolean} [issuerActionItemsFirst] 
         * @param {boolean} [findRequiresAttention] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchIndex: async (page?: number, size?: number, search?: string, eventId?: number, issuerEntityId?: number, issuanceId?: number, assetType?: string, matchState?: Array<string>, transactionDate?: string, accountId?: number, orderItemId?: Array<number>, issuerActionItemsFirst?: boolean, findRequiresAttention?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/matches`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (issuanceId !== undefined) {
                localVarQueryParameter['issuance_id'] = issuanceId;
            }

            if (assetType !== undefined) {
                localVarQueryParameter['asset_type'] = assetType;
            }

            if (matchState) {
                localVarQueryParameter['match_state'] = matchState.join(COLLECTION_FORMATS.csv);
            }

            if (transactionDate !== undefined) {
                localVarQueryParameter['transaction_date'] = transactionDate;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (orderItemId) {
                localVarQueryParameter['order_item_id'] = orderItemId.join(COLLECTION_FORMATS.csv);
            }

            if (issuerActionItemsFirst !== undefined) {
                localVarQueryParameter['issuer_action_items_first'] = issuerActionItemsFirst;
            }

            if (findRequiresAttention !== undefined) {
                localVarQueryParameter['find_requires_attention'] = findRequiresAttention;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary executes the rofr assigns action (assign,waive,block)
         * @param {MatchRofrAssignCreateRequestContract} matchRofrAssignCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchRofrAssignCreate: async (matchRofrAssignCreateRequestContract: MatchRofrAssignCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchRofrAssignCreateRequestContract' is not null or undefined
            assertParamExists('matchRofrAssignCreate', 'matchRofrAssignCreateRequestContract', matchRofrAssignCreateRequestContract)
            const localVarPath = changeUrl(`/api/match-rofr-assigns`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matchRofrAssignCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} matchId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchRofrAssignShow: async (matchId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'matchId' is not null or undefined
            assertParamExists('matchRofrAssignShow', 'matchId', matchId)
            const localVarPath = changeUrl(`/api/match-rofr-assigns/{match_id}`)
                .replace(`{${"match_id"}}`, encodeURIComponent(String(matchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary retrieves the match with the provided id
         * @param {number} id 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchShow: async (id: number, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matchShow', 'id', id)
            const localVarPath = changeUrl(`/api/matches/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Retrieves match statistics associated with the event(issuance of) or the issuer_entity (all issuances of)
         * @param {string} [eventId] 
         * @param {string} [issuanceId] 
         * @param {string} [issuerEntityId] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchStatisticShow: async (eventId?: string, issuanceId?: string, issuerEntityId?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/match-statistics`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (issuanceId !== undefined) {
                localVarQueryParameter['issuance_id'] = issuanceId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updates the match with the provided id
         * @param {number} id 
         * @param {MatchUpdateRequestContract} matchUpdateRequestContract 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchUpdate: async (id: number, matchUpdateRequestContract: MatchUpdateRequestContract, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('matchUpdate', 'id', id)
            // verify required parameter 'matchUpdateRequestContract' is not null or undefined
            assertParamExists('matchUpdate', 'matchUpdateRequestContract', matchUpdateRequestContract)
            const localVarPath = changeUrl(`/api/matches/{id}`)
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(matchUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MatchApi - functional programming interface
 * @export
 */
export const MatchApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MatchApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [startDate] 
         * @param {Array<string>} [matchState] 
         * @param {boolean} [findRequiresAttention] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async issuerEntityMatchIndex(id: number, page?: number, size?: number, startDate?: string, matchState?: Array<string>, findRequiresAttention?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.issuerEntityMatchIndex(id, page, size, startDate, matchState, findRequiresAttention, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary allocates match
         * @param {MatchDesignationCreateRequestContract} matchDesignationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchDesignationCreate(matchDesignationCreateRequestContract: MatchDesignationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchDesignationCreate(matchDesignationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves designated matches of parent match
         * @param {number} matchId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchDesignationShow(matchId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchDesignationShow(matchId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {string} [assetType] 
         * @param {Array<string>} [matchState] 
         * @param {string} [transactionDate] 
         * @param {number} [accountId] 
         * @param {Array<number>} [orderItemId] 
         * @param {boolean} [issuerActionItemsFirst] 
         * @param {boolean} [findRequiresAttention] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchIndex(page?: number, size?: number, search?: string, eventId?: number, issuerEntityId?: number, issuanceId?: number, assetType?: string, matchState?: Array<string>, transactionDate?: string, accountId?: number, orderItemId?: Array<number>, issuerActionItemsFirst?: boolean, findRequiresAttention?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchIndex(page, size, search, eventId, issuerEntityId, issuanceId, assetType, matchState, transactionDate, accountId, orderItemId, issuerActionItemsFirst, findRequiresAttention, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary executes the rofr assigns action (assign,waive,block)
         * @param {MatchRofrAssignCreateRequestContract} matchRofrAssignCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchRofrAssignCreate(matchRofrAssignCreateRequestContract: MatchRofrAssignCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchRofrAssignCreate(matchRofrAssignCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} matchId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchRofrAssignShow(matchId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchRofrAssignShow(matchId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary retrieves the match with the provided id
         * @param {number} id 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchShow(id: number, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchShow(id, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Retrieves match statistics associated with the event(issuance of) or the issuer_entity (all issuances of)
         * @param {string} [eventId] 
         * @param {string} [issuanceId] 
         * @param {string} [issuerEntityId] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchStatisticShow(eventId?: string, issuanceId?: string, issuerEntityId?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchStatistic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchStatisticShow(eventId, issuanceId, issuerEntityId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updates the match with the provided id
         * @param {number} id 
         * @param {MatchUpdateRequestContract} matchUpdateRequestContract 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async matchUpdate(id: number, matchUpdateRequestContract: MatchUpdateRequestContract, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MatchAggregate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.matchUpdate(id, matchUpdateRequestContract, includeField, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MatchApi - factory interface
 * @export
 */
export const MatchApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MatchApiFp(configuration)
    return {
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} id 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [startDate] 
         * @param {Array<string>} [matchState] 
         * @param {boolean} [findRequiresAttention] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        issuerEntityMatchIndex(id: number, page?: number, size?: number, startDate?: string, matchState?: Array<string>, findRequiresAttention?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchIndex> {
            return localVarFp.issuerEntityMatchIndex(id, page, size, startDate, matchState, findRequiresAttention, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary allocates match
         * @param {MatchDesignationCreateRequestContract} matchDesignationCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchDesignationCreate(matchDesignationCreateRequestContract: MatchDesignationCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchIndex> {
            return localVarFp.matchDesignationCreate(matchDesignationCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves designated matches of parent match
         * @param {number} matchId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchDesignationShow(matchId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchIndex> {
            return localVarFp.matchDesignationShow(matchId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [eventId] 
         * @param {number} [issuerEntityId] 
         * @param {number} [issuanceId] 
         * @param {string} [assetType] 
         * @param {Array<string>} [matchState] 
         * @param {string} [transactionDate] 
         * @param {number} [accountId] 
         * @param {Array<number>} [orderItemId] 
         * @param {boolean} [issuerActionItemsFirst] 
         * @param {boolean} [findRequiresAttention] 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchIndex(page?: number, size?: number, search?: string, eventId?: number, issuerEntityId?: number, issuanceId?: number, assetType?: string, matchState?: Array<string>, transactionDate?: string, accountId?: number, orderItemId?: Array<number>, issuerActionItemsFirst?: boolean, findRequiresAttention?: boolean, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchIndex> {
            return localVarFp.matchIndex(page, size, search, eventId, issuerEntityId, issuanceId, assetType, matchState, transactionDate, accountId, orderItemId, issuerActionItemsFirst, findRequiresAttention, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary executes the rofr assigns action (assign,waive,block)
         * @param {MatchRofrAssignCreateRequestContract} matchRofrAssignCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchRofrAssignCreate(matchRofrAssignCreateRequestContract: MatchRofrAssignCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchIndex> {
            return localVarFp.matchRofrAssignCreate(matchRofrAssignCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves matches associated with the issuer entity specified in the path
         * @param {number} matchId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchRofrAssignShow(matchId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchIndex> {
            return localVarFp.matchRofrAssignShow(matchId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary retrieves the match with the provided id
         * @param {number} id 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchShow(id: number, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, options?: any): AxiosPromise<MatchAggregate> {
            return localVarFp.matchShow(id, includeField, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Retrieves match statistics associated with the event(issuance of) or the issuer_entity (all issuances of)
         * @param {string} [eventId] 
         * @param {string} [issuanceId] 
         * @param {string} [issuerEntityId] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchStatisticShow(eventId?: string, issuanceId?: string, issuerEntityId?: string, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<MatchStatistic> {
            return localVarFp.matchStatisticShow(eventId, issuanceId, issuerEntityId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updates the match with the provided id
         * @param {number} id 
         * @param {MatchUpdateRequestContract} matchUpdateRequestContract 
         * @param {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>} [includeField] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        matchUpdate(id: number, matchUpdateRequestContract: MatchUpdateRequestContract, includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>, options?: any): AxiosPromise<MatchAggregate> {
            return localVarFp.matchUpdate(id, matchUpdateRequestContract, includeField, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for issuerEntityMatchIndex operation in MatchApi.
 * @export
 * @interface MatchApiIssuerEntityMatchIndexRequest
 */
export interface MatchApiIssuerEntityMatchIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly id: number

    /**
     * 
     * @type {number}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly startDate?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly matchState?: Array<string>

    /**
     * 
     * @type {boolean}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly findRequiresAttention?: boolean

    /**
     * 
     * @type {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>

    /**
     * 
     * @type {string}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiIssuerEntityMatchIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchDesignationCreate operation in MatchApi.
 * @export
 * @interface MatchApiMatchDesignationCreateRequest
 */
export interface MatchApiMatchDesignationCreateRequest {
    /**
     * 
     * @type {MatchDesignationCreateRequestContract}
     * @memberof MatchApiMatchDesignationCreate
     */
    readonly matchDesignationCreateRequestContract: MatchDesignationCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchDesignationShow operation in MatchApi.
 * @export
 * @interface MatchApiMatchDesignationShowRequest
 */
export interface MatchApiMatchDesignationShowRequest {
    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchDesignationShow
     */
    readonly matchId: number

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchDesignationShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchIndex operation in MatchApi.
 * @export
 * @interface MatchApiMatchIndexRequest
 */
export interface MatchApiMatchIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchIndex
     */
    readonly eventId?: number

    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchIndex
     */
    readonly issuanceId?: number

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly assetType?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof MatchApiMatchIndex
     */
    readonly matchState?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly transactionDate?: string

    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {Array<number>}
     * @memberof MatchApiMatchIndex
     */
    readonly orderItemId?: Array<number>

    /**
     * 
     * @type {boolean}
     * @memberof MatchApiMatchIndex
     */
    readonly issuerActionItemsFirst?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof MatchApiMatchIndex
     */
    readonly findRequiresAttention?: boolean

    /**
     * 
     * @type {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>}
     * @memberof MatchApiMatchIndex
     */
    readonly includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchRofrAssignCreate operation in MatchApi.
 * @export
 * @interface MatchApiMatchRofrAssignCreateRequest
 */
export interface MatchApiMatchRofrAssignCreateRequest {
    /**
     * 
     * @type {MatchRofrAssignCreateRequestContract}
     * @memberof MatchApiMatchRofrAssignCreate
     */
    readonly matchRofrAssignCreateRequestContract: MatchRofrAssignCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchRofrAssignShow operation in MatchApi.
 * @export
 * @interface MatchApiMatchRofrAssignShowRequest
 */
export interface MatchApiMatchRofrAssignShowRequest {
    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchRofrAssignShow
     */
    readonly matchId: number

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchRofrAssignShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchShow operation in MatchApi.
 * @export
 * @interface MatchApiMatchShowRequest
 */
export interface MatchApiMatchShowRequest {
    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchShow
     */
    readonly id: number

    /**
     * 
     * @type {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>}
     * @memberof MatchApiMatchShow
     */
    readonly includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>
}

/**
 * Request parameters for matchStatisticShow operation in MatchApi.
 * @export
 * @interface MatchApiMatchStatisticShowRequest
 */
export interface MatchApiMatchStatisticShowRequest {
    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly eventId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly issuanceId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly issuerEntityId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof MatchApiMatchStatisticShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for matchUpdate operation in MatchApi.
 * @export
 * @interface MatchApiMatchUpdateRequest
 */
export interface MatchApiMatchUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof MatchApiMatchUpdate
     */
    readonly id: number

    /**
     * 
     * @type {MatchUpdateRequestContract}
     * @memberof MatchApiMatchUpdate
     */
    readonly matchUpdateRequestContract: MatchUpdateRequestContract

    /**
     * 
     * @type {Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>}
     * @memberof MatchApiMatchUpdate
     */
    readonly includeField?: Array<'match_timeline' | 'rofr_timeline' | 'latest_comment'>
}

/**
 * MatchApi - object-oriented interface
 * @export
 * @class MatchApi
 * @extends {BaseAPI}
 */
export class MatchApi extends BaseAPI {
    /**
     * 
     * @summary Retrieves matches associated with the issuer entity specified in the path
     * @param {MatchApiIssuerEntityMatchIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public issuerEntityMatchIndex(requestParameters: MatchApiIssuerEntityMatchIndexRequest, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).issuerEntityMatchIndex(requestParameters.id, requestParameters.page, requestParameters.size, requestParameters.startDate, requestParameters.matchState, requestParameters.findRequiresAttention, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary allocates match
     * @param {MatchApiMatchDesignationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchDesignationCreate(requestParameters: MatchApiMatchDesignationCreateRequest, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchDesignationCreate(requestParameters.matchDesignationCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves designated matches of parent match
     * @param {MatchApiMatchDesignationShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchDesignationShow(requestParameters: MatchApiMatchDesignationShowRequest, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchDesignationShow(requestParameters.matchId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves matches associated with the issuer entity specified in the path
     * @param {MatchApiMatchIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchIndex(requestParameters: MatchApiMatchIndexRequest = {}, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchIndex(requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.eventId, requestParameters.issuerEntityId, requestParameters.issuanceId, requestParameters.assetType, requestParameters.matchState, requestParameters.transactionDate, requestParameters.accountId, requestParameters.orderItemId, requestParameters.issuerActionItemsFirst, requestParameters.findRequiresAttention, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary executes the rofr assigns action (assign,waive,block)
     * @param {MatchApiMatchRofrAssignCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchRofrAssignCreate(requestParameters: MatchApiMatchRofrAssignCreateRequest, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchRofrAssignCreate(requestParameters.matchRofrAssignCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves matches associated with the issuer entity specified in the path
     * @param {MatchApiMatchRofrAssignShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchRofrAssignShow(requestParameters: MatchApiMatchRofrAssignShowRequest, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchRofrAssignShow(requestParameters.matchId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary retrieves the match with the provided id
     * @param {MatchApiMatchShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchShow(requestParameters: MatchApiMatchShowRequest, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchShow(requestParameters.id, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Retrieves match statistics associated with the event(issuance of) or the issuer_entity (all issuances of)
     * @param {MatchApiMatchStatisticShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchStatisticShow(requestParameters: MatchApiMatchStatisticShowRequest = {}, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchStatisticShow(requestParameters.eventId, requestParameters.issuanceId, requestParameters.issuerEntityId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updates the match with the provided id
     * @param {MatchApiMatchUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MatchApi
     */
    public matchUpdate(requestParameters: MatchApiMatchUpdateRequest, options?: AxiosRequestConfig) {
        return MatchApiFp(this.configuration).matchUpdate(requestParameters.id, requestParameters.matchUpdateRequestContract, requestParameters.includeField, options).then((request) => request(this.axios, this.basePath));
    }
}

export type IssuerEntityMatchIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const IssuerEntityMatchIndexErrorCodes = [
];

export type MatchDesignationCreateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchDesignationCreateErrorCodes = [
    401,
    422,
];

export type MatchDesignationShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchDesignationShowErrorCodes = [
];

export type MatchIndexErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchIndexErrorCodes = [
];

export type MatchRofrAssignCreateErrorTypes =

    | {
        status: 403;
        data: EventsCapTableStatisticShow;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchRofrAssignCreateErrorCodes = [
    403,
];

export type MatchRofrAssignShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchRofrAssignShowErrorCodes = [
];

export type MatchShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchShowErrorCodes = [
];

export type MatchStatisticShowErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchStatisticShowErrorCodes = [
];

export type MatchUpdateErrorTypes =

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const MatchUpdateErrorCodes = [
];


