import { type DefaultTheme } from 'styled-components';

import { APP_CFG_API_URL } from '@npm/config';
import { Flex } from '@npm/core/ui/components/atoms/common';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { BulletPoints } from '@npm/core/ui/components/molecules/BulletPoints';
import { CompanyLogo } from '@npm/core/ui/components/molecules/CompanyLogo';
import {
  type ExtendedColumnType,
  TABLE_BREAKPOINTS,
  TableDividerColumn as TableDividerColumnOriginal,
} from '@npm/core/ui/components/molecules/Table';
import { type BreakpointsReturnType } from '@npm/core/ui/hooks/useBreakpoints';
import type { VenusApi } from '@npm/data-access';

import { PremiumLockButton } from '../../auth/user/components/PremiumLockButton';

import { AvailableLabel } from './AvailableLabel';
import {
  DataAvailabilities,
  dataAvailabilityLabels,
} from './CompaniesData.types';
import { CompaniesDataTableViewDetailButton } from './CompaniesDataTableViewDetailButton';

import * as S from './CompaniesDataTable.styles';

type GetCompaniesDataConfigOptions = {
  breakpoints: BreakpointsReturnType;
  theme: DefaultTheme;
};

const DOT_COLUMN_PROPS = {
  align: 'center',
  width: '80px',
} as const;

const CAP_TABLE_AND_WATERFALL = 'Cap Table & Waterfall';

export const getConfig = ({
  breakpoints: { isDesktop, isMobile },
  theme,
}: GetCompaniesDataConfigOptions) => {
  const renderDot = (value: boolean, withPremiumLock = true) => {
    const dot = (
      <Icon
        name="dot"
        size="xxs"
        color={
          value
            ? theme.color.info.typography.primary
            : theme.color.general.backgrounds.muted
        }
      />
    );
    return withPremiumLock ? (
      <PremiumLockButton width={DOT_COLUMN_PROPS.width} showDash={!value}>
        {dot}
      </PremiumLockButton>
    ) : (
      dot
    );
  };

  const companyLogoColumn = {
    title: 'Company',
    key: 'company',
    render(_, { company_name, company_npm_guid, sectors }) {
      return (
        <CompanyLogo
          url={`${APP_CFG_API_URL}/api/issuer-entities/${company_npm_guid}/logo`}
          name={company_name}
          description={sectors?.join(', ')}
          isCompanyCard
        />
      );
    },
    align: 'left',
    width: isMobile ? undefined : '300px',
  } satisfies ExtendedColumnType<VenusApi.CompanyOverview>;

  const tableDividerColumn = {
    ...TableDividerColumnOriginal,
    width: 1,
  } satisfies ExtendedColumnType<VenusApi.CompanyOverview>;

  const actionColumn = {
    title: '',
    align: 'center',
    width: 1,
    render: (_, record) => {
      return (
        <CompaniesDataTableViewDetailButton
          companyNpmGuid={record.company_npm_guid}
        />
      );
    },
  } satisfies ExtendedColumnType<VenusApi.CompanyOverview>;

  if (isDesktop) {
    return [
      companyLogoColumn,
      {
        title: 'Pricing',
        key: DataAvailabilities.TapeDPrice,
        render: (_, { tape_d_price_available }) =>
          renderDot(tape_d_price_available),
        ...DOT_COLUMN_PROPS,
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.BidsOrAsks],
        key: DataAvailabilities.BidsOrAsks,
        render: (_, { bids_or_asks_available }) =>
          renderDot(bids_or_asks_available),
        ...DOT_COLUMN_PROPS,
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.PrimaryRounds],
        key: DataAvailabilities.PrimaryRounds,
        render: (_, { primary_rounds_available }) =>
          renderDot(primary_rounds_available),
        ...DOT_COLUMN_PROPS,
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.Four09AValuations],
        key: DataAvailabilities.Four09AValuations,
        render: (_, record) => renderDot(record['409as_available']),
        ...DOT_COLUMN_PROPS,
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.MutualFundMarks],
        key: DataAvailabilities.MutualFundMarks,
        render: (_, { mtms_available }) => renderDot(mtms_available),
        ...DOT_COLUMN_PROPS,
      },
      tableDividerColumn,
      {
        title: dataAvailabilityLabels[DataAvailabilities.FinancingActivity],
        key: DataAvailabilities.FinancingActivity,
        render: (_, { financing_activity_available }) =>
          renderDot(financing_activity_available, false),
        ...DOT_COLUMN_PROPS,
      },
      tableDividerColumn,
      {
        title: dataAvailabilityLabels[DataAvailabilities.IssuedShares],
        key: DataAvailabilities.IssuedShares,
        render: (_, { issued_shares_available }) =>
          renderDot(issued_shares_available),
        ...DOT_COLUMN_PROPS,
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.Waterfall],
        key: DataAvailabilities.Waterfall,
        render: (_, { waterfall_available }) => renderDot(waterfall_available),
        ...DOT_COLUMN_PROPS,
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.DealTerms],
        key: DataAvailabilities.DealTerms,
        render: (_, { deal_terms_available }) =>
          renderDot(deal_terms_available),
        ...DOT_COLUMN_PROPS,
      },
      tableDividerColumn,
      {
        title: dataAvailabilityLabels[DataAvailabilities.SourceDocuments],
        key: DataAvailabilities.SourceDocuments,
        render: (_, { source_documents_available }) =>
          renderDot(source_documents_available),
        ...DOT_COLUMN_PROPS,
      },
      tableDividerColumn,
      {
        title: dataAvailabilityLabels[DataAvailabilities.Profile],
        key: DataAvailabilities.Profile,
        render: (_, { profile_data_available }) =>
          renderDot(profile_data_available, false),
        ...DOT_COLUMN_PROPS,
      },
      actionColumn,
    ] satisfies ExtendedColumnType<VenusApi.CompanyOverview>[];
  } else if (isMobile) {
    return [
      companyLogoColumn,
      {
        width: '130px',
        title: 'Available Data Groups',
        titleInfo: (
          <BulletPoints
            title="Dots represent"
            bulletPoints={[
              dataAvailabilityLabels[DataAvailabilities.TapeDPrice],
              dataAvailabilityLabels[DataAvailabilities.FinancingActivity],
              CAP_TABLE_AND_WATERFALL,
              dataAvailabilityLabels[DataAvailabilities.SourceDocuments],
              dataAvailabilityLabels[DataAvailabilities.Profile],
            ]}
          />
        ),
        render: (_, record) => {
          const {
            tape_d_price_available,
            bids_or_asks_available,
            primary_rounds_available,
            mtms_available,
            financing_activity_available,
            issued_shares_available,
            waterfall_available,
            deal_terms_available,
            source_documents_available,
            profile_data_available,
          } = record;

          return (
            <S.DotsFlex>
              {renderDot(
                tape_d_price_available ||
                  bids_or_asks_available ||
                  primary_rounds_available ||
                  record['409as_available'] ||
                  mtms_available
              )}
              {renderDot(financing_activity_available, false)}
              {renderDot(
                issued_shares_available ||
                  waterfall_available ||
                  deal_terms_available
              )}
              {renderDot(source_documents_available)}
              {renderDot(profile_data_available, false)}
            </S.DotsFlex>
          );
        },
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.TapeDPrice],
        responsive: TABLE_BREAKPOINTS.NONE,
        key: DataAvailabilities.TapeDPrice,
        render: (_, record) => {
          const {
            tape_d_price_available,
            bids_or_asks_available,
            primary_rounds_available,
            mtms_available,
          } = record;
          return (
            <AvailableLabel
              requiresPremium
              booleans={[
                tape_d_price_available,
                bids_or_asks_available,
                primary_rounds_available,
                record['409as_available'],
                mtms_available,
              ]}
            />
          );
        },
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.FinancingActivity],
        responsive: TABLE_BREAKPOINTS.NONE,
        key: DataAvailabilities.FinancingActivity,
        render: (_, { financing_activity_available }) => {
          return <AvailableLabel booleans={[financing_activity_available]} />;
        },
      },
      {
        title: CAP_TABLE_AND_WATERFALL,
        responsive: TABLE_BREAKPOINTS.NONE,
        key: 'cap_table_waterfall',
        render: (
          _,
          { issued_shares_available, waterfall_available, deal_terms_available }
        ) => {
          return (
            <AvailableLabel
              requiresPremium
              booleans={[
                issued_shares_available,
                waterfall_available,
                deal_terms_available,
              ]}
            />
          );
        },
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.SourceDocuments],
        responsive: TABLE_BREAKPOINTS.NONE,
        key: DataAvailabilities.SourceDocuments,
        render: (_, { source_documents_available }) => {
          return (
            <AvailableLabel
              requiresPremium
              booleans={[source_documents_available]}
            />
          );
        },
      },
      {
        title: dataAvailabilityLabels[DataAvailabilities.Profile],
        responsive: TABLE_BREAKPOINTS.NONE,
        key: DataAvailabilities.Profile,
        render: (_, { profile_data_available }) => {
          return <AvailableLabel booleans={[profile_data_available]} />;
        },
      },
      actionColumn,
    ] satisfies ExtendedColumnType<VenusApi.CompanyOverview>[];
  }

  return [
    companyLogoColumn,
    {
      title: 'Pricing',
      titleInfo: (
        <BulletPoints
          title="Dots represent"
          bulletPoints={[
            dataAvailabilityLabels[DataAvailabilities.TapeDPrice],
            dataAvailabilityLabels[DataAvailabilities.BidsOrAsks],
            dataAvailabilityLabels[DataAvailabilities.PrimaryRounds],
            dataAvailabilityLabels[DataAvailabilities.Four09AValuations],
            dataAvailabilityLabels[DataAvailabilities.MutualFundMarks],
          ]}
        />
      ),
      key: DataAvailabilities.TapeDPrice,
      render: (_, record) => {
        const {
          tape_d_price_available,
          bids_or_asks_available,
          primary_rounds_available,
          mtms_available,
        } = record;
        return (
          <S.DotsFlex>
            {renderDot(tape_d_price_available)}
            {renderDot(bids_or_asks_available)}
            {renderDot(primary_rounds_available)}
            {renderDot(record['409as_available'])}
            {renderDot(mtms_available)}
          </S.DotsFlex>
        );
      },
      ...DOT_COLUMN_PROPS,
      width: '104px',
    },
    tableDividerColumn,
    {
      title: dataAvailabilityLabels[DataAvailabilities.FinancingActivity],
      key: DataAvailabilities.FinancingActivity,
      render: (_, { financing_activity_available }) =>
        renderDot(financing_activity_available, false),
      ...DOT_COLUMN_PROPS,
    },
    tableDividerColumn,
    {
      title: CAP_TABLE_AND_WATERFALL,
      titleInfo: (
        <BulletPoints
          title="Dots represent"
          bulletPoints={[
            dataAvailabilityLabels[DataAvailabilities.IssuedShares],
            dataAvailabilityLabels[DataAvailabilities.Waterfall],
            dataAvailabilityLabels[DataAvailabilities.DealTerms],
          ]}
        />
      ),
      key: 'cap_table_waterfall',
      render: (
        _,
        { issued_shares_available, waterfall_available, deal_terms_available }
      ) => (
        <S.DotsFlex>
          {renderDot(issued_shares_available)}
          {renderDot(waterfall_available)}
          {renderDot(deal_terms_available)}
        </S.DotsFlex>
      ),
      ...DOT_COLUMN_PROPS,
    },
    tableDividerColumn,
    {
      title: dataAvailabilityLabels[DataAvailabilities.SourceDocuments],
      key: DataAvailabilities.SourceDocuments,
      render: (_, { source_documents_available }) => (
        <Flex justify="center">{renderDot(source_documents_available)}</Flex>
      ),
      ...DOT_COLUMN_PROPS,
    },
    tableDividerColumn,
    {
      title: dataAvailabilityLabels[DataAvailabilities.Profile],
      key: DataAvailabilities.Profile,
      render: (_, { profile_data_available }) =>
        renderDot(profile_data_available, false),
      ...DOT_COLUMN_PROPS,
    },
    actionColumn,
  ] satisfies ExtendedColumnType<VenusApi.CompanyOverview>[];
};
