import React, { memo, useCallback, useMemo } from 'react';

import {
  DrawerEnum,
  useActiveDrawerStore,
} from '@npm/core/ui/components/organisms/Drawer';
import {
  Navigation,
  NavigationMenu,
} from '@npm/core/ui/components/organisms/Layout';
import { useSidebarStore } from '@npm/core/ui/components/organisms/Layout/Sidebar/Sidebar.store';
import { filterMenuItemsByFeatureFlag } from '@npm/core/ui/routes';
import {
  CbAccountSponsorshipType,
  CbActionCounterType,
  useActionCounterShow,
} from '@npm/data-access';
import { useFeatureFlags } from '@npm/features/app/featureFlags/featureFlags.context';
import { useFeatureFlagMenuItem } from '@npm/features/app/featureFlags/Menu/useFeatureFlagMenuItem';
import { useHelpMenuItem } from '@npm/features/app/zendesk/menu/useHelpMenuItem';
import { useUserContextStore } from '@npm/features/auth/user/context';
import { useUserMenuItems } from '@npm/features/auth/user/menu/menuItems';
import { useObo, useUserRole } from '@npm/features/auth/user/role';
import {
  NotificationDrawer,
  useNotificationMenuItem,
} from '@npm/features/notifications';

import { OptIn } from '../../features/optIn/OptIn';

import { getInvestorSidebarItems } from './config/investor-workstation';

export const InvestorMenu = memo(function InvestorMenu() {
  const { helpMenuItem, handleHelpMenuItemClick } = useHelpMenuItem();
  const userContext = useUserContextStore(store => store);
  const userMenuItems = useUserMenuItems();
  const userRole = useUserRole();
  const { oboAccount, oboRepresentative, isObo } = useObo();
  const { isOpen, openDrawer, closeDrawer } = useActiveDrawerStore();
  const { isEnabled } = useFeatureFlags();
  const { featureFlagMenuItem, handleFeatureFlagMenuItemClick } =
    useFeatureFlagMenuItem();
  const isCollapsed = useSidebarStore(store => store.isCollapsed);
  const {
    notificationMenuItem,
    handleNotificationMenuItemClick,
    notificationDrawerProps,
  } = useNotificationMenuItem();

  const handleOptInModalClick = useCallback((key: string) => {
    if (
      // we intercept the key without the leading slash on mobile
      key === 'second-market/investor/opt-in' ||
      key === '/second-market/investor/opt-in'
    ) {
      openDrawer(DrawerEnum.OPT_IN);
      return true;
    }
    closeDrawer();
    return false;
  }, []);

  const { event, nda, agreement } = CbActionCounterType.items;
  const { data: counterData } = useActionCounterShow(
    {
      category: [event, nda, agreement],
    },
    {
      onError: err => {
        // ignoring error, becase this is only for badge numbers
        console.log(err);
      },
    }
  );

  const isSponsored = CbAccountSponsorshipType.isSponsoredAccount(
    userContext.sponsorshipType
  );

  const menuItems = useMemo(
    () =>
      filterMenuItemsByFeatureFlag(
        isEnabled,
        getInvestorSidebarItems(
          userContext,
          userRole,
          {
            representative: oboRepresentative,
            account: oboAccount,
          },
          counterData,
          isSponsored && !isObo ? notificationMenuItem : undefined
        )
      ),
    [
      isSponsored,
      isObo,
      notificationMenuItem,
      userContext,
      userRole,
      oboAccount,
      oboRepresentative,
      counterData,
      isEnabled,
    ]
  );

  const bottomMenuItems = useMemo(
    () => [featureFlagMenuItem, helpMenuItem, ...userMenuItems].filter(Boolean),
    [helpMenuItem, userMenuItems, featureFlagMenuItem]
  );

  return (
    <NavigationMenu $isCollapsed={isCollapsed}>
      <Navigation
        menuItems={menuItems}
        handleClick={key =>
          handleOptInModalClick(key) || handleNotificationMenuItemClick(key)
        }
      />
      <Navigation
        menuItems={bottomMenuItems}
        handleClick={key =>
          handleHelpMenuItemClick(key) || handleFeatureFlagMenuItemClick(key)
        }
      />
      {isOpen(DrawerEnum.OPT_IN) && <OptIn />}
      <NotificationDrawer {...notificationDrawerProps} />
    </NavigationMenu>
  );
});
