import { type DefaultPrivacyLevel, datadogRum } from '@datadog/browser-rum';
import { isEqual } from 'lodash';

import {
  APP_CFG_API_URL,
  APP_CFG_ENVIRONMENT,
  APP_CFG_SHA,
  APP_CFG_TAG,
} from '@npm/config';

import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_ENABLED,
  DATADOG_ENV,
  DATADOG_SERVICE,
  DATADOG_SITE,
} from './datadog.env';
import { DATADOG_PRIVACY_LEVEL } from './datadog.env.prod';
import {
  type DdContextVariableKey,
  type DdWorkstation,
  DD_WORKSTATION_TO_ROLE_NAME,
} from './datadog.types';
import { isNPMUser } from './datadog.utils';

// DO NOT CHANGE, IT'S BECAUSE OTERWISE BUILD WILL REMOVE THE WHOLE DATADOG
const isEnabled = isEqual(DATADOG_ENABLED, 'true');

const init = () => {
  if (!isEnabled) {
    return;
  }

  const getPrivacyLevel = (envPrivacyLevel: string): DefaultPrivacyLevel => {
    // Checking input variable if it is correct
    switch (envPrivacyLevel) {
      case 'mask':
        return 'mask';
      case 'allow':
        return 'allow';
      default:
        return 'mask-user-input';
    }
  };

  const getVersion = (tag: string, sha: string): string => {
    return [tag, sha]
      .map(v => v?.trim())
      .filter(Boolean)
      .join('-');
  };

  const getEnv = (ddEnv: string, appEnv: string) => {
    return ddEnv?.trim()?.length ? ddEnv?.trim() : appEnv;
  };

  try {
    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: DATADOG_SITE,
      service: DATADOG_SERVICE,
      // Specify a version number to identify the deployed version of your application in Datadog
      version: getVersion(APP_CFG_TAG, APP_CFG_SHA),
      env: getEnv(DATADOG_ENV, APP_CFG_ENVIRONMENT),
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackUserInteractions: true,
      defaultPrivacyLevel: getPrivacyLevel(DATADOG_PRIVACY_LEVEL),
      allowedTracingUrls: [APP_CFG_API_URL],
      trackViewsManually: true,
      trackFrustrations: true,
    });

    datadogRum.startSessionReplayRecording();
  } catch (e) {
    // nothing
    console.error(e);
  }
};

const startView = (viewName: string) => {
  if (!isEnabled || !viewName?.length) {
    return;
  }

  try {
    datadogRum.startView({ name: viewName });
  } catch (e) {
    // nothing
  }
};

const setUser = (
  user: {
    id: string;
    isPremium: boolean;
    email: string;
    fullName?: string;
  } | null
) => {
  if (!isEnabled) {
    return;
  }

  try {
    datadogRum.setUser(
      user?.id
        ? {
            id: user.id,
            // enable once NPM confirm it is ok to send those data to
            // name: user.fullName,
            // email: user.email,
          }
        : {}
    );

    addContextVariable('premium_user', !!user && user.isPremium);
    addContextVariable('npm_user', !!user && isNPMUser(user.email));
  } catch (e) {
    // nothing
  }
};

const addAction = (actionName: string, context?: object | undefined) => {
  if (!isEnabled) {
    return;
  }
  datadogRum.addAction(actionName, context);
};

const addContextVariable = (
  key: DdContextVariableKey,
  value: string | number | boolean
) => {
  datadogRum.addRumGlobalContext(key, value);
};

const setRoleType = (workstation: DdWorkstation) => {
  const roleName = DD_WORKSTATION_TO_ROLE_NAME[workstation];
  addContextVariable('role_type', roleName);
};

export const DatadogService = {
  init,
  startView,
  setUser,
  setRoleType,
  addAction,
  addContextVariable,
};
