import React, { useEffect } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useOktaAuth } from '@okta/okta-react';

import { useHistory } from '@npm/core/ui/components/molecules/Link';
import { useCurrentRoute } from '@npm/core/ui/hooks/useCurrentRoute';
import { getFullName } from '@npm/core/ui/utils/formatters';
import {
  CbSubscriptionPlan,
  getApiErrorCode,
  useUserShowCurrentLazy,
} from '@npm/data-access';
import { DatadogService } from '@npm/utils';

import { useUserContextStore } from '../../auth/user/context';
import { InitialLoadingPart, useInitialLoadingStore } from '../initialLoading';

const isErrorPath = (location: string) => {
  const regex = /^\/([4-5]\d{2})/g;
  return regex.test(location);
};

export const withUser = <T,>(Component: React.ComponentType<T>) => {
  return (hocProps: T) => {
    const history = useHistory();
    const handleError = useErrorHandler();
    const currentRoute = useCurrentRoute();
    const { authState, oktaAuth } = useOktaAuth();
    const user = useUserContextStore(store => store.user);
    const setUser = useUserContextStore(store => store.setUser);
    const finishLoadingPartOfApp = useInitialLoadingStore(
      store => store.finishLoadingPart
    );

    const [loadUser] = useUserShowCurrentLazy({
      config: {
        onError: e => {
          // ignoring 441 because it's handled in initTOSHandling
          if (getApiErrorCode(e) !== 441) {
            handleError(e);
          }
          finishLoadingPartOfApp(InitialLoadingPart.User);
        },
      },
    });

    useEffect(() => {
      (async () => {
        if (!authState) {
          setUser(authState, null);
          DatadogService.setUser(null);
          return;
        }

        if (
          isErrorPath(history.location.pathname) ||
          currentRoute?.route?.needLogin === false
        ) {
          finishLoadingPartOfApp(InitialLoadingPart.User);
          return;
        }

        if (!authState.isAuthenticated) {
          oktaAuth.signInWithRedirect({
            originalUri: window.location.href,
          });
          return;
        }

        if (!user && currentRoute?.route?.needUser !== false) {
          try {
            const user = await loadUser();
            setUser(authState, user);
            finishLoadingPartOfApp(InitialLoadingPart.User);

            const isPremium =
              user.subscription_plan?.code ===
                CbSubscriptionPlan.items.premium ||
              user.subscription_plan?.code === CbSubscriptionPlan.items.trial;

            DatadogService.setUser({
              id: user.id.toString(),
              isPremium,
              email: user.person?.email,
              fullName: getFullName(
                user.person?.first,
                user.person?.last,
                user.person?.middle
              ),
            });
          } catch (err) {
            console.error(err);
          }
        }
      })();
    }, [
      authState,
      user,
      currentRoute?.route?.needUser,
      currentRoute?.route?.needLogin,
    ]);

    if (
      !user &&
      !isErrorPath(history.location.pathname) &&
      currentRoute?.route?.needUser !== false &&
      currentRoute?.route?.needLogin !== false
    ) {
      return null;
    }

    return <Component {...hocProps} />;
  };
};
