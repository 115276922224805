import React, { useState } from 'react';
import { Grid } from 'antd';

import { Button } from '@npm/core/ui/components/atoms/Button';
import { Icon } from '@npm/core/ui/components/atoms/Icon';
import { Loader } from '@npm/core/ui/components/atoms/Loader';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { Table } from '@npm/core/ui/components/molecules/Table';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import { type UnpackArrayType } from '@npm/core/ui/utils/unpack.type';
import {
  type AccountShowResponseType,
  useAccountShowLazy,
} from '@npm/data-access';

import { columns } from './RepresentativesListPopup.columns';

const { useBreakpoint } = Grid;

type Props = {
  count: number;
  accountId: number;
};

export const RepresentativesListPopup = (props: Props) => {
  const { isMobile } = useBreakpoints();
  const [isVisible, setIsVisible] = useState(false);
  const breakpoint = useBreakpoint();

  const [fetch, { data, isLoading }] = useAccountShowLazy({
    variables: {
      id: props.accountId?.toString(),
    },
  });

  const handleClose = () => {
    setIsVisible(false);
  };

  const loadRepresentativesAndShowModal = async () => {
    setIsVisible(true);
    await fetch();
  };

  return (
    <>
      <Button
        size="sm"
        variant="text"
        icon={<Icon name="eye" />}
        onClick={loadRepresentativesAndShowModal}
        style={{ minWidth: 'auto' }}
        data-dd-action-name="Representatives Button"
      >
        {props.count}
      </Button>
      <Modal
        $maxHeight={430}
        open={isVisible}
        title={`Representatives for ${data?.name || ''}`}
        okText="Close"
        onCancel={handleClose}
        onOk={handleClose}
        isFullScreen={isMobile}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <Table<UnpackArrayType<AccountShowResponseType, 'users'>>
            dataSource={data?.users}
            columns={columns}
            expandable={{
              columnWidth: '20px',
              showExpandColumn: !breakpoint.lg,
              rowExpandable: () => !breakpoint.lg,
            }}
          />
        )}
      </Modal>
    </>
  );
};
