import React, { type ComponentProps, useEffect, useState } from 'react';
import { isEmpty } from 'lodash';

import { SELECT_PAGE_SIZE_BIG } from '@npm/core/ui/components/atoms/Select';
import { type Drawer } from '@npm/core/ui/components/organisms/Drawer';
import { useLayoutConfig } from '@npm/core/ui/components/organisms/Layout';
import { useBreakpoints } from '@npm/core/ui/hooks/useBreakpoints';
import {
  SM_COMPANY_CAROUSEL_HEIGHT,
  SM_HEADER_DESKTOP_HEIGHT,
} from '@npm/core/ui/hooks/useSMHeaderHeight';
import { usePreservableQueryParamsStore } from '@npm/core/ui/services/paths';
import { type IssuerEntityAggregate } from '@npm/data-access';

import { useCurrentAccount } from '../../../auth/user/role';
import { CompanyCard } from '../../../company';
import { useRequestCompanyCoverage } from '../../../company/RequestCompanyCoverage';
import {
  type FilterOptions,
  decodeFilters,
  encodeFilters,
  FILTER_QUERY_KEY,
  usePersistedFilters,
} from '../../../filters';

import {
  type IssuerEntityIndexFilterParams,
  ISSUER_ENTITY_INDEX_INITIAL_FILTER_VALUES,
  IssuerEntityIndexFilter,
} from './Filter';

import * as S from './CompanySelectDrawer.styles';

const BASE_COMPANY_SELECT_SIDEBAR_TOP_POSITION =
  SM_HEADER_DESKTOP_HEIGHT + SM_COMPANY_CAROUSEL_HEIGHT;

type Props = {
  selectedCompanyId?: number | null;
  selectedCompany?: IssuerEntityAggregate;
  onSelect: (company?: IssuerEntityAggregate) => void;
  variant?: 'broker' | 'investor';
} & ComponentProps<typeof Drawer>;

export const SM_COMPANY_SELECT_CONTENT_ID = 'npm-sm-company-select-content';

// Should be in sync with routes.secondMarket.ts workstationPrefix
export const SM_PRESERVABLE_QUERY_PARAMS_GROUP = 'second-market';

// this is needed for persisted filters to work as expected
// we need to store a non-undefined value in the query params
// or the filters will fallback to the initial value (companyActive === 'all_active')
const filterOptions: FilterOptions<IssuerEntityIndexFilterParams> = {
  customEncode: obj => {
    if (!obj) return undefined;

    return encodeFilters({
      ...obj,
      companyActive:
        obj.companyActive === undefined ? 'all' : obj.companyActive,
    });
  },
  customDecode: obj => {
    const decoded = decodeFilters(obj);

    if (decoded.companyActive === 'all') {
      decoded.companyActive = undefined;
    }

    return decoded;
  },
  customizeFilterCount: {
    // Ignore, to not impact "Clear All" button
    companyActive: false,
    portfolioOnly: false,
    watchlistOnly: false,
  },
  resetQueryOnUnmount: false,
};

export const CompanySelectDrawer = ({
  selectedCompanyId,
  onSelect,
  variant,
  onClose,
  selectedCompany,
  ...drawerProps
}: Props) => {
  const showFilterTabs = variant === 'investor';
  const [companySelectSidebarTopPosition, setCompanySelectSidebarTopPosition] =
    useState(BASE_COMPANY_SELECT_SIDEBAR_TOP_POSITION);
  const { isXlDesktop } = useBreakpoints();
  const layoutConfig = useLayoutConfig();
  const { isMobile, isTablet } = layoutConfig;

  const hasCollapsedSidebar = !isMobile && !isTablet;
  const isShownInsideDrawer = !isXlDesktop;

  const { isOboOrAccountCentric } = useCurrentAccount();

  const [setPreservableQueryParams] = usePreservableQueryParamsStore(x => [
    x.setPreservableQueryParams,
  ]);

  const baseVariables: IssuerEntityIndexFilterParams = {
    size: SELECT_PAGE_SIZE_BIG,
    unverifiedHoldings: true,
    ...ISSUER_ENTITY_INDEX_INITIAL_FILTER_VALUES,
  };

  const [
    { variables, isFilterApplied },
    { handleSubmit, initialFilterValues, totalActiveFilters },
  ] = usePersistedFilters<IssuerEntityIndexFilterParams>(
    FILTER_QUERY_KEY.companySelect,
    baseVariables,
    filterOptions
  );

  const searchTerm = variables?.search;
  const { requestCoverageAreaComponent } = useRequestCompanyCoverage({
    searchTerm,
  });

  useEffect(() => {
    const initialValues = isEmpty(initialFilterValues)
      ? ISSUER_ENTITY_INDEX_INITIAL_FILTER_VALUES
      : initialFilterValues;

    setPreservableQueryParams(
      SM_PRESERVABLE_QUERY_PARAMS_GROUP,
      {
        [FILTER_QUERY_KEY.companySelect]:
          filterOptions.customEncode(initialValues),
      },
      false
    );

    const handleWindowScroll = () => {
      setCompanySelectSidebarTopPosition(
        window.scrollY > SM_HEADER_DESKTOP_HEIGHT
          ? SM_HEADER_DESKTOP_HEIGHT
          : BASE_COMPANY_SELECT_SIDEBAR_TOP_POSITION
      );
    };

    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);

  const handleSubmitWithPreservableQuery = (
    filterValues: Partial<IssuerEntityIndexFilterParams>
  ) => {
    handleSubmit(filterValues);

    setPreservableQueryParams(
      SM_PRESERVABLE_QUERY_PARAMS_GROUP,
      {
        [FILTER_QUERY_KEY.companySelect]:
          filterOptions.customEncode(filterValues),
      },
      false
    );
  };

  const content = (
    <S.ContentContainer id={SM_COMPANY_SELECT_CONTENT_ID}>
      <IssuerEntityIndexFilter
        variables={variables}
        initialFilterValues={initialFilterValues}
        handleSubmit={handleSubmitWithPreservableQuery}
        totalActiveFilters={totalActiveFilters}
        showFilterTabs={showFilterTabs}
        isShownInsideDrawer={isShownInsideDrawer}
        closeDrawer={onClose}
      />

      {!!selectedCompany && (
        <S.SelectedCompanyWrapper>
          <CompanyCard
            company={selectedCompany}
            isSelected
            showSelectedIcon
            onSelect={() => onSelect?.(undefined)}
            variant="mini"
          />
        </S.SelectedCompanyWrapper>
      )}

      <S.CompanyCardsStyled
        variables={variables}
        selectedCompanyId={selectedCompanyId}
        onSelect={onSelect}
        variant="broker"
        companyCardProps={{
          showSelectedIcon: true,
          watchlistButtonColor: 'success',
          showWatchlistButton: isOboOrAccountCentric,
        }}
        isFilterApplied={isFilterApplied}
        filterCompanies={company => company.id !== selectedCompanyId}
        noDataArea={searchTerm ? requestCoverageAreaComponent : undefined}
      />
    </S.ContentContainer>
  );

  return !isShownInsideDrawer ? (
    <S.CompanySelectSidebar $topPosition={companySelectSidebarTopPosition}>
      {content}
    </S.CompanySelectSidebar>
  ) : (
    <S.CompanySelectDrawer
      placement="left"
      noHeader
      onClose={onClose}
      destroyOnClose={false}
      footerHeight={0}
      {...drawerProps}
      $hasCollapsedSidebar={hasCollapsedSidebar}
    >
      {content}
    </S.CompanySelectDrawer>
  );
};
