import React, { useState } from 'react';
import { type ModalProps } from 'antd';

import { Flex, Margin } from '@npm/core/ui/components/atoms/common';
import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { Form } from '@npm/core/ui/components/atoms/Form';
import {
  InputFormItem,
  VALIDATION_RULES,
} from '@npm/core/ui/components/atoms/FormItem';
import { Input } from '@npm/core/ui/components/atoms/Input';
import { Radio, RadioGroup } from '@npm/core/ui/components/atoms/Radio';
import { TooltipInfoIcon } from '@npm/core/ui/components/atoms/Tooltip';
import { Text } from '@npm/core/ui/components/atoms/Typography';
import { Modal } from '@npm/core/ui/components/molecules/Modal';
import { type AffiliateDisclosureType } from '@npm/data-access';

import { useUserContextStore } from '../../../../auth/user/context';

import { buttonLabels } from './AffiliateDisclosureWithSignatureModal.constants';

type Values = {
  affiliateDisclosure: AffiliateDisclosureType;
  signature?: string;
};

type Props = {
  isOpen: boolean;
  onSubmit: (options: Values) => void;
  reviewTerms: string;
} & ModalProps;

export const AffiliateDisclosureWithSignatureModal = ({
  isOpen,
  onCancel,
  onSubmit,
  reviewTerms,
}: Props) => {
  const initialValues = {
    affiliateDisclosure: undefined,
    signature: undefined,
  };

  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [values, setValues] = useState<Values>(initialValues);

  const getFullName = useUserContextStore(store => store.getFullName);
  const fullLegalName = getFullName();

  const isSubmitDisabled =
    !values?.affiliateDisclosure || values?.signature !== fullLegalName;

  return (
    <Modal
      title="Order Terms & Affiliate Disclosure"
      afterClose={() => {
        setSubmitLoading(false);
        setValues(initialValues);
      }}
      size="lg"
      open={isOpen}
      okText="I Confirm"
      onOk={() => {
        onSubmit(values);
        setSubmitLoading(true);
      }}
      okButtonProps={{ disabled: isSubmitDisabled, loading: isSubmitLoading }}
      cancelText="Cancel"
      onCancel={onCancel}
    >
      <Text size="sm" colorVariant="secondary" marginBottom="md">
        {reviewTerms}
      </Text>
      <Divider />
      <Margin bottom="sm">
        <Flex align="center" gap="xs">
          <Text size="sm" colorVariant="primary">
            Are you a Company Insider or Affiliate of the Company?
          </Text>
          <TooltipInfoIcon
            title={
              <Text size="sm" colorVariant="primary">
                <Text as="span" size="sm" colorVariant="primary" weight="bold">
                  Company Insider
                </Text>{' '}
                is any officer or director of the Company or shareholder with
                more than 10% ownership of the Company. <br />
                <br />
                <Text as="span" size="sm" colorVariant="primary" weight="bold">
                  Affiliate
                </Text>{' '}
                is a person that has the power to direct the management and
                policies of the Company, whether through the ownership of voting
                securities, by contract, or otherwise.
              </Text>
            }
          />
        </Flex>
      </Margin>
      <RadioGroup>
        {Object.entries(buttonLabels).map(([key, value]) => (
          <Radio
            key={key}
            value={key}
            label={value}
            onChange={e =>
              setValues({ ...values, affiliateDisclosure: e.target.value })
            }
          />
        ))}
      </RadioGroup>
      <Divider marginTop="md" />
      <Form>
        <InputFormItem
          name="signature"
          label="Your Signature"
          rules={[
            VALIDATION_RULES.required(),
            VALIDATION_RULES.matchSpecificValue(
              fullLegalName,
              `Signature must match your full legal name "${fullLegalName}"`
            ),
          ]}
          validateTrigger="onBlur"
          extra={`Your full legal name “${fullLegalName}”`}
          data-dd-privacy="mask"
        >
          <Input
            placeholder="Your Signature"
            onChange={e => setValues({ ...values, signature: e.target.value })}
          />
        </InputFormItem>
      </Form>
    </Modal>
  );
};
