import React from 'react';
import type { ValidateStatus } from 'antd/es/form/FormItem';

import { Divider } from '@npm/core/ui/components/atoms/Divider';
import { FormattableInput } from '@npm/core/ui/components/atoms/FormattableInput';
import { VALIDATION_RULES } from '@npm/core/ui/components/atoms/FormItem';
import {
  CharLimitHelper,
  Input,
  TextArea,
} from '@npm/core/ui/components/atoms/Input';
import { Heading, Text } from '@npm/core/ui/components/atoms/Typography';
import { formatABARoutingNumber } from '@npm/core/ui/utils/formatters';
import { abaFormItemValidationRules } from '@npm/core/ui/utils/validators';

import { DOMESTIC_FORM_FIELDS_ENUM } from '../../BankAccountInformation.types';
import { useAlternateBeneficiary } from '../BankAccountInformationForm.hooks';

import * as S from '../BankAccountInformationForm.styles';

type Props = {
  allowAlternateBeneficiary?: boolean;
  onAbaNumberValidStatusChange: (status: ValidateStatus) => void;
  accountNameExtraDetails: string;
};

export const DomesticFormFields = ({
  allowAlternateBeneficiary = false,
  onAbaNumberValidStatusChange,
  accountNameExtraDetails,
}: Props) => {
  const { isBeneficiaryEditEnabled, checkboxComponent } =
    useAlternateBeneficiary(allowAlternateBeneficiary);

  return (
    <>
      <S.SectionSpacer $size="md">
        <Divider colorVariant="primary" />
      </S.SectionSpacer>
      <S.SectionSpacer $size="md">
        <Heading variant="h3" marginBottom="xl">
          Bank Account Information
        </Heading>
        <S.FlexWrap>
          <S.FormItem
            label="ABA Routing Number"
            extra="Please note your ABA routing number for wires is sometimes different from the ABA routing number that appear on your checks."
            name={DOMESTIC_FORM_FIELDS_ENUM.ABA_NUMBER}
            rules={abaFormItemValidationRules}
          >
            <FormattableInput
              placeholder="123456789"
              formatter={formatABARoutingNumber}
              inputMode="numeric"
              onValidationStatusChange={onAbaNumberValidStatusChange}
            />
          </S.FormItem>
          <S.FormItem
            label="Bank Name"
            name={DOMESTIC_FORM_FIELDS_ENUM.BANK_NAME}
          >
            <Input placeholder="Bank Name" />
          </S.FormItem>
        </S.FlexWrap>
        <S.FlexWrap>
          <S.FormItem
            label="Bank Account Number"
            name={DOMESTIC_FORM_FIELDS_ENUM.ACCOUNT_NUMBER}
          >
            <Input placeholder="1234567890" />
          </S.FormItem>
          <S.FormItem
            label="Name on Account"
            name={DOMESTIC_FORM_FIELDS_ENUM.BENEFICIARY_NAME}
            extra={
              <>
                <Text size="sm">{accountNameExtraDetails}</Text>
                {checkboxComponent}
              </>
            }
          >
            <Input
              placeholder="Name on Account"
              disabled={!isBeneficiaryEditEnabled}
            />
          </S.FormItem>
        </S.FlexWrap>
      </S.SectionSpacer>

      <Divider />

      <S.SectionSpacer $size="md">
        <S.FormItem
          name={DOMESTIC_FORM_FIELDS_ENUM.ADDITIONAL_INSTRUCTIONS}
          required={false}
          rules={[VALIDATION_RULES.max(35)]}
          label="Additional Wiring Instructions"
          requiredMark="optional"
          extra={
            <CharLimitHelper
              fieldName={DOMESTIC_FORM_FIELDS_ENUM.ADDITIONAL_INSTRUCTIONS}
              limit={35}
            />
          }
        >
          <TextArea placeholder="Wiring Instructions" />
        </S.FormItem>
      </S.SectionSpacer>
    </>
  );
};
