/* tslint:disable */
/* eslint-disable */
/**
 * Nasdaq Private Market API v1
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import {changeUrl} from '../../config/axios';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DestroySuccess } from '../model';
// @ts-ignore
import { ErrorValidation } from '../model';
// @ts-ignore
import { EventsSubmissionCreateRequestContract } from '../model';
// @ts-ignore
import { EventsSubmissionUpdateRequestContract } from '../model';
// @ts-ignore
import { SecondmarketSubmissionCreateRequestContract } from '../model';
// @ts-ignore
import { SecondmarketSubmissionForClientOrders } from '../model';
// @ts-ignore
import { SecondmarketSubmissionIndex } from '../model';
// @ts-ignore
import { Submission } from '../model';
// @ts-ignore
import { SubmissionCreateRequestContract } from '../model';
// @ts-ignore
import { SubmissionIndex } from '../model';
/**
 * SubmissionApi - axios parameter creator
 * @export
 */
export const SubmissionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary create a submission for the specified event
         * @param {number} eventId 
         * @param {EventsSubmissionCreateRequestContract} eventsSubmissionCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionCreate: async (eventId: number, eventsSubmissionCreateRequestContract: EventsSubmissionCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventSubmissionCreate', 'eventId', eventId)
            // verify required parameter 'eventsSubmissionCreateRequestContract' is not null or undefined
            assertParamExists('eventSubmissionCreate', 'eventsSubmissionCreateRequestContract', eventsSubmissionCreateRequestContract)
            const localVarPath = changeUrl(`/api/events/{event_id}/submissions`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventsSubmissionCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary the specified submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionDelete: async (eventId: number, submissionId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventSubmissionDelete', 'eventId', eventId)
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('eventSubmissionDelete', 'submissionId', submissionId)
            const localVarPath = changeUrl(`/api/events/{event_id}/submissions/{submission_id}`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list submissions for specified event
         * @param {number} eventId 
         * @param {number} [accountId] 
         * @param {string} [search] 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>} [includeField] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [submissionState] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionIndex: async (eventId: number, accountId?: number, search?: string, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>, page?: number, size?: number, submissionState?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventSubmissionIndex', 'eventId', eventId)
            const localVarPath = changeUrl(`/api/events/{event_id}/submissions`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (submissionState) {
                localVarQueryParameter['submission_state'] = submissionState.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary the specified submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionShow: async (eventId: number, submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventSubmissionShow', 'eventId', eventId)
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('eventSubmissionShow', 'submissionId', submissionId)
            const localVarPath = changeUrl(`/api/events/{event_id}/submissions/{submission_id}`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary the specified M&A submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {EventsSubmissionUpdateRequestContract} eventsSubmissionUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionUpdate: async (eventId: number, submissionId: number, eventsSubmissionUpdateRequestContract: EventsSubmissionUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'eventId' is not null or undefined
            assertParamExists('eventSubmissionUpdate', 'eventId', eventId)
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('eventSubmissionUpdate', 'submissionId', submissionId)
            // verify required parameter 'eventsSubmissionUpdateRequestContract' is not null or undefined
            assertParamExists('eventSubmissionUpdate', 'eventsSubmissionUpdateRequestContract', eventsSubmissionUpdateRequestContract)
            const localVarPath = changeUrl(`/api/events/{event_id}/submissions/{submission_id}`)
                .replace(`{${"event_id"}}`, encodeURIComponent(String(eventId)))
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(eventsSubmissionUpdateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an ioi or firm submission
         * @param {SecondmarketSubmissionCreateRequestContract} secondmarketSubmissionCreateRequestContract 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketSubmissionCreate: async (secondmarketSubmissionCreateRequestContract: SecondmarketSubmissionCreateRequestContract, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'secondmarketSubmissionCreateRequestContract' is not null or undefined
            assertParamExists('secondmarketSubmissionCreate', 'secondmarketSubmissionCreateRequestContract', secondmarketSubmissionCreateRequestContract)
            const localVarPath = changeUrl(`/api/secondmarket/submissions`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(secondmarketSubmissionCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list submissions based on filters
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [assetType] 
         * @param {string} [placedAt] 
         * @param {string} [receivedAt] 
         * @param {string} [publishedAt] 
         * @param {string} [accountType] 
         * @param {string} [side] 
         * @param {Array<string>} [submissionState] 
         * @param {Array<string>} [orderItemState] 
         * @param {string} [expirationDate] 
         * @param {Array<string>} [orderEntryType] 
         * @param {number} [orderItemId] 
         * @param {number} [createdByUserId] 
         * @param {number} [routingBrokerId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {string} [sort] 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketSubmissionIndex: async (accountId?: number, issuerEntityId?: number, assetType?: string, placedAt?: string, receivedAt?: string, publishedAt?: string, accountType?: string, side?: string, submissionState?: Array<string>, orderItemState?: Array<string>, expirationDate?: string, orderEntryType?: Array<string>, orderItemId?: number, createdByUserId?: number, routingBrokerId?: number, page?: number, size?: number, search?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, sort?: string, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/secondmarket/submissions`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (assetType !== undefined) {
                localVarQueryParameter['asset_type'] = assetType;
            }

            if (placedAt !== undefined) {
                localVarQueryParameter['placed_at'] = placedAt;
            }

            if (receivedAt !== undefined) {
                localVarQueryParameter['received_at'] = receivedAt;
            }

            if (publishedAt !== undefined) {
                localVarQueryParameter['published_at'] = publishedAt;
            }

            if (accountType !== undefined) {
                localVarQueryParameter['account_type'] = accountType;
            }

            if (side !== undefined) {
                localVarQueryParameter['side'] = side;
            }

            if (submissionState) {
                localVarQueryParameter['submission_state'] = submissionState.join(COLLECTION_FORMATS.csv);
            }

            if (orderItemState) {
                localVarQueryParameter['order_item_state'] = orderItemState.join(COLLECTION_FORMATS.csv);
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expiration_date'] = expirationDate;
            }

            if (orderEntryType) {
                localVarQueryParameter['order_entry_type'] = orderEntryType.join(COLLECTION_FORMATS.csv);
            }

            if (orderItemId !== undefined) {
                localVarQueryParameter['order_item_id'] = orderItemId;
            }

            if (createdByUserId !== undefined) {
                localVarQueryParameter['created_by_user_id'] = createdByUserId;
            }

            if (routingBrokerId !== undefined) {
                localVarQueryParameter['routing_broker_id'] = routingBrokerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (sizeRangeStart !== undefined) {
                localVarQueryParameter['size_range_start'] = sizeRangeStart;
            }

            if (sizeRangeEnd !== undefined) {
                localVarQueryParameter['size_range_end'] = sizeRangeEnd;
            }

            if (atsPool) {
                localVarQueryParameter['ats_pool'] = atsPool.join(COLLECTION_FORMATS.csv);
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show a submission
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketSubmissionShow: async (submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('secondmarketSubmissionShow', 'submissionId', submissionId)
            const localVarPath = changeUrl(`/api/secondmarket/submissions/{submission_id}`)
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary create an ioi or firm submission
         * @param {SubmissionCreateRequestContract} submissionCreateRequestContract 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionCreate: async (submissionCreateRequestContract: SubmissionCreateRequestContract, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionCreateRequestContract' is not null or undefined
            assertParamExists('submissionCreate', 'submissionCreateRequestContract', submissionCreateRequestContract)
            const localVarPath = changeUrl(`/api/submissions`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(submissionCreateRequestContract, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary list submissions based on filters
         * @param {number} [eventId] 
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [assetType] 
         * @param {string} [placedAt] 
         * @param {string} [receivedAt] 
         * @param {string} [publishedAt] 
         * @param {string} [accountType] 
         * @param {string} [side] 
         * @param {Array<string>} [submissionState] 
         * @param {Array<string>} [orderItemState] 
         * @param {string} [expirationDate] 
         * @param {Array<string>} [orderEntryType] 
         * @param {string} [orderEntryCategory] 
         * @param {number} [orderItemId] 
         * @param {number} [createdByUserId] 
         * @param {number} [routingBrokerId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [clientOrders] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {string} [sort] 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionIndex: async (eventId?: number, accountId?: number, issuerEntityId?: number, assetType?: string, placedAt?: string, receivedAt?: string, publishedAt?: string, accountType?: string, side?: string, submissionState?: Array<string>, orderItemState?: Array<string>, expirationDate?: string, orderEntryType?: Array<string>, orderEntryCategory?: string, orderItemId?: number, createdByUserId?: number, routingBrokerId?: number, page?: number, size?: number, search?: string, clientOrders?: boolean, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, sort?: string, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/submissions`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (eventId !== undefined) {
                localVarQueryParameter['event_id'] = eventId;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['account_id'] = accountId;
            }

            if (issuerEntityId !== undefined) {
                localVarQueryParameter['issuer_entity_id'] = issuerEntityId;
            }

            if (assetType !== undefined) {
                localVarQueryParameter['asset_type'] = assetType;
            }

            if (placedAt !== undefined) {
                localVarQueryParameter['placed_at'] = placedAt;
            }

            if (receivedAt !== undefined) {
                localVarQueryParameter['received_at'] = receivedAt;
            }

            if (publishedAt !== undefined) {
                localVarQueryParameter['published_at'] = publishedAt;
            }

            if (accountType !== undefined) {
                localVarQueryParameter['account_type'] = accountType;
            }

            if (side !== undefined) {
                localVarQueryParameter['side'] = side;
            }

            if (submissionState) {
                localVarQueryParameter['submission_state'] = submissionState.join(COLLECTION_FORMATS.csv);
            }

            if (orderItemState) {
                localVarQueryParameter['order_item_state'] = orderItemState.join(COLLECTION_FORMATS.csv);
            }

            if (expirationDate !== undefined) {
                localVarQueryParameter['expiration_date'] = expirationDate;
            }

            if (orderEntryType) {
                localVarQueryParameter['order_entry_type'] = orderEntryType.join(COLLECTION_FORMATS.csv);
            }

            if (orderEntryCategory !== undefined) {
                localVarQueryParameter['order_entry_category'] = orderEntryCategory;
            }

            if (orderItemId !== undefined) {
                localVarQueryParameter['order_item_id'] = orderItemId;
            }

            if (createdByUserId !== undefined) {
                localVarQueryParameter['created_by_user_id'] = createdByUserId;
            }

            if (routingBrokerId !== undefined) {
                localVarQueryParameter['routing_broker_id'] = routingBrokerId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (clientOrders !== undefined) {
                localVarQueryParameter['client_orders'] = clientOrders;
            }

            if (sizeRangeStart !== undefined) {
                localVarQueryParameter['size_range_start'] = sizeRangeStart;
            }

            if (sizeRangeEnd !== undefined) {
                localVarQueryParameter['size_range_end'] = sizeRangeEnd;
            }

            if (atsPool) {
                localVarQueryParameter['ats_pool'] = atsPool.join(COLLECTION_FORMATS.csv);
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary show a submission
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionShow: async (submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'submissionId' is not null or undefined
            assertParamExists('submissionShow', 'submissionId', submissionId)
            const localVarPath = changeUrl(`/api/submissions/{submission_id}`)
                .replace(`{${"submission_id"}}`, encodeURIComponent(String(submissionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (includeField) {
                localVarQueryParameter['include_field'] = includeField.join(COLLECTION_FORMATS.csv);
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary download submissions csv
         * @param {Array<string>} [submissionTypes] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDownloadIndex: async (submissionTypes?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = changeUrl(`/api/submissions/download`);
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication api_key required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (submissionTypes) {
                localVarQueryParameter['submission_types'] = submissionTypes;
            }

            if (xApiVersion !== undefined && xApiVersion !== null) {
                localVarHeaderParameter['X-Api-Version'] = String(xApiVersion);
            }

            if (xWorkstation !== undefined && xWorkstation !== null) {
                localVarHeaderParameter['X-Workstation'] = String(xWorkstation);
            }

            if (xOboAccountId !== undefined && xOboAccountId !== null) {
                localVarHeaderParameter['X-Obo-Account-Id'] = String(xOboAccountId);
            }

            if (xOboUserId !== undefined && xOboUserId !== null) {
                localVarHeaderParameter['X-Obo-User-Id'] = String(xOboUserId);
            }

            if (xAcrossAccounts !== undefined && xAcrossAccounts !== null) {
                localVarHeaderParameter['X-Across-Accounts'] = String(xAcrossAccounts);
            }

            if (xUserRoleId !== undefined && xUserRoleId !== null) {
                localVarHeaderParameter['X-User-Role-Id'] = String(xUserRoleId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SubmissionApi - functional programming interface
 * @export
 */
export const SubmissionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SubmissionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary create a submission for the specified event
         * @param {number} eventId 
         * @param {EventsSubmissionCreateRequestContract} eventsSubmissionCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventSubmissionCreate(eventId: number, eventsSubmissionCreateRequestContract: EventsSubmissionCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventSubmissionCreate(eventId, eventsSubmissionCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary the specified submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventSubmissionDelete(eventId: number, submissionId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DestroySuccess>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventSubmissionDelete(eventId, submissionId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list submissions for specified event
         * @param {number} eventId 
         * @param {number} [accountId] 
         * @param {string} [search] 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>} [includeField] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [submissionState] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventSubmissionIndex(eventId: number, accountId?: number, search?: string, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>, page?: number, size?: number, submissionState?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventSubmissionIndex(eventId, accountId, search, includeField, page, size, submissionState, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary the specified submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventSubmissionShow(eventId: number, submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventSubmissionShow(eventId, submissionId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary the specified M&A submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {EventsSubmissionUpdateRequestContract} eventsSubmissionUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async eventSubmissionUpdate(eventId: number, submissionId: number, eventsSubmissionUpdateRequestContract: EventsSubmissionUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.eventSubmissionUpdate(eventId, submissionId, eventsSubmissionUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create an ioi or firm submission
         * @param {SecondmarketSubmissionCreateRequestContract} secondmarketSubmissionCreateRequestContract 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketSubmissionCreate(secondmarketSubmissionCreateRequestContract: SecondmarketSubmissionCreateRequestContract, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketSubmissionForClientOrders>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketSubmissionCreate(secondmarketSubmissionCreateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list submissions based on filters
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [assetType] 
         * @param {string} [placedAt] 
         * @param {string} [receivedAt] 
         * @param {string} [publishedAt] 
         * @param {string} [accountType] 
         * @param {string} [side] 
         * @param {Array<string>} [submissionState] 
         * @param {Array<string>} [orderItemState] 
         * @param {string} [expirationDate] 
         * @param {Array<string>} [orderEntryType] 
         * @param {number} [orderItemId] 
         * @param {number} [createdByUserId] 
         * @param {number} [routingBrokerId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {string} [sort] 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketSubmissionIndex(accountId?: number, issuerEntityId?: number, assetType?: string, placedAt?: string, receivedAt?: string, publishedAt?: string, accountType?: string, side?: string, submissionState?: Array<string>, orderItemState?: Array<string>, expirationDate?: string, orderEntryType?: Array<string>, orderItemId?: number, createdByUserId?: number, routingBrokerId?: number, page?: number, size?: number, search?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, sort?: string, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketSubmissionIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketSubmissionIndex(accountId, issuerEntityId, assetType, placedAt, receivedAt, publishedAt, accountType, side, submissionState, orderItemState, expirationDate, orderEntryType, orderItemId, createdByUserId, routingBrokerId, page, size, search, sizeRangeStart, sizeRangeEnd, atsPool, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show a submission
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondmarketSubmissionShow(submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SecondmarketSubmissionForClientOrders>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondmarketSubmissionShow(submissionId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary create an ioi or firm submission
         * @param {SubmissionCreateRequestContract} submissionCreateRequestContract 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionCreate(submissionCreateRequestContract: SubmissionCreateRequestContract, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionCreate(submissionCreateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary list submissions based on filters
         * @param {number} [eventId] 
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [assetType] 
         * @param {string} [placedAt] 
         * @param {string} [receivedAt] 
         * @param {string} [publishedAt] 
         * @param {string} [accountType] 
         * @param {string} [side] 
         * @param {Array<string>} [submissionState] 
         * @param {Array<string>} [orderItemState] 
         * @param {string} [expirationDate] 
         * @param {Array<string>} [orderEntryType] 
         * @param {string} [orderEntryCategory] 
         * @param {number} [orderItemId] 
         * @param {number} [createdByUserId] 
         * @param {number} [routingBrokerId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [clientOrders] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {string} [sort] 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionIndex(eventId?: number, accountId?: number, issuerEntityId?: number, assetType?: string, placedAt?: string, receivedAt?: string, publishedAt?: string, accountType?: string, side?: string, submissionState?: Array<string>, orderItemState?: Array<string>, expirationDate?: string, orderEntryType?: Array<string>, orderEntryCategory?: string, orderItemId?: number, createdByUserId?: number, routingBrokerId?: number, page?: number, size?: number, search?: string, clientOrders?: boolean, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, sort?: string, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubmissionIndex>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionIndex(eventId, accountId, issuerEntityId, assetType, placedAt, receivedAt, publishedAt, accountType, side, submissionState, orderItemState, expirationDate, orderEntryType, orderEntryCategory, orderItemId, createdByUserId, routingBrokerId, page, size, search, clientOrders, sizeRangeStart, sizeRangeEnd, atsPool, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary show a submission
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionShow(submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Submission>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionShow(submissionId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary download submissions csv
         * @param {Array<string>} [submissionTypes] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async submissionsDownloadIndex(submissionTypes?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.submissionsDownloadIndex(submissionTypes, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SubmissionApi - factory interface
 * @export
 */
export const SubmissionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SubmissionApiFp(configuration)
    return {
        /**
         * 
         * @summary create a submission for the specified event
         * @param {number} eventId 
         * @param {EventsSubmissionCreateRequestContract} eventsSubmissionCreateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionCreate(eventId: number, eventsSubmissionCreateRequestContract: EventsSubmissionCreateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Submission> {
            return localVarFp.eventSubmissionCreate(eventId, eventsSubmissionCreateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary the specified submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionDelete(eventId: number, submissionId: number, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<DestroySuccess> {
            return localVarFp.eventSubmissionDelete(eventId, submissionId, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list submissions for specified event
         * @param {number} eventId 
         * @param {number} [accountId] 
         * @param {string} [search] 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>} [includeField] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {Array<string>} [submissionState] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionIndex(eventId: number, accountId?: number, search?: string, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>, page?: number, size?: number, submissionState?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SubmissionIndex> {
            return localVarFp.eventSubmissionIndex(eventId, accountId, search, includeField, page, size, submissionState, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary the specified submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionShow(eventId: number, submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Submission> {
            return localVarFp.eventSubmissionShow(eventId, submissionId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary the specified M&A submission
         * @param {number} eventId 
         * @param {number} submissionId 
         * @param {EventsSubmissionUpdateRequestContract} eventsSubmissionUpdateRequestContract 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        eventSubmissionUpdate(eventId: number, submissionId: number, eventsSubmissionUpdateRequestContract: EventsSubmissionUpdateRequestContract, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Submission> {
            return localVarFp.eventSubmissionUpdate(eventId, submissionId, eventsSubmissionUpdateRequestContract, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an ioi or firm submission
         * @param {SecondmarketSubmissionCreateRequestContract} secondmarketSubmissionCreateRequestContract 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketSubmissionCreate(secondmarketSubmissionCreateRequestContract: SecondmarketSubmissionCreateRequestContract, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SecondmarketSubmissionForClientOrders> {
            return localVarFp.secondmarketSubmissionCreate(secondmarketSubmissionCreateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list submissions based on filters
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [assetType] 
         * @param {string} [placedAt] 
         * @param {string} [receivedAt] 
         * @param {string} [publishedAt] 
         * @param {string} [accountType] 
         * @param {string} [side] 
         * @param {Array<string>} [submissionState] 
         * @param {Array<string>} [orderItemState] 
         * @param {string} [expirationDate] 
         * @param {Array<string>} [orderEntryType] 
         * @param {number} [orderItemId] 
         * @param {number} [createdByUserId] 
         * @param {number} [routingBrokerId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {string} [sort] 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketSubmissionIndex(accountId?: number, issuerEntityId?: number, assetType?: string, placedAt?: string, receivedAt?: string, publishedAt?: string, accountType?: string, side?: string, submissionState?: Array<string>, orderItemState?: Array<string>, expirationDate?: string, orderEntryType?: Array<string>, orderItemId?: number, createdByUserId?: number, routingBrokerId?: number, page?: number, size?: number, search?: string, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, sort?: string, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SecondmarketSubmissionIndex> {
            return localVarFp.secondmarketSubmissionIndex(accountId, issuerEntityId, assetType, placedAt, receivedAt, publishedAt, accountType, side, submissionState, orderItemState, expirationDate, orderEntryType, orderItemId, createdByUserId, routingBrokerId, page, size, search, sizeRangeStart, sizeRangeEnd, atsPool, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show a submission
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondmarketSubmissionShow(submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SecondmarketSubmissionForClientOrders> {
            return localVarFp.secondmarketSubmissionShow(submissionId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary create an ioi or firm submission
         * @param {SubmissionCreateRequestContract} submissionCreateRequestContract 
         * @param {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionCreate(submissionCreateRequestContract: SubmissionCreateRequestContract, includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Submission> {
            return localVarFp.submissionCreate(submissionCreateRequestContract, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary list submissions based on filters
         * @param {number} [eventId] 
         * @param {number} [accountId] 
         * @param {number} [issuerEntityId] 
         * @param {string} [assetType] 
         * @param {string} [placedAt] 
         * @param {string} [receivedAt] 
         * @param {string} [publishedAt] 
         * @param {string} [accountType] 
         * @param {string} [side] 
         * @param {Array<string>} [submissionState] 
         * @param {Array<string>} [orderItemState] 
         * @param {string} [expirationDate] 
         * @param {Array<string>} [orderEntryType] 
         * @param {string} [orderEntryCategory] 
         * @param {number} [orderItemId] 
         * @param {number} [createdByUserId] 
         * @param {number} [routingBrokerId] 
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [search] 
         * @param {boolean} [clientOrders] 
         * @param {number} [sizeRangeStart] 
         * @param {number} [sizeRangeEnd] 
         * @param {Array<string>} [atsPool] 
         * @param {string} [sort] 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionIndex(eventId?: number, accountId?: number, issuerEntityId?: number, assetType?: string, placedAt?: string, receivedAt?: string, publishedAt?: string, accountType?: string, side?: string, submissionState?: Array<string>, orderItemState?: Array<string>, expirationDate?: string, orderEntryType?: Array<string>, orderEntryCategory?: string, orderItemId?: number, createdByUserId?: number, routingBrokerId?: number, page?: number, size?: number, search?: string, clientOrders?: boolean, sizeRangeStart?: number, sizeRangeEnd?: number, atsPool?: Array<string>, sort?: string, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<SubmissionIndex> {
            return localVarFp.submissionIndex(eventId, accountId, issuerEntityId, assetType, placedAt, receivedAt, publishedAt, accountType, side, submissionState, orderItemState, expirationDate, orderEntryType, orderEntryCategory, orderItemId, createdByUserId, routingBrokerId, page, size, search, clientOrders, sizeRangeStart, sizeRangeEnd, atsPool, sort, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary show a submission
         * @param {number} submissionId 
         * @param {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>} [includeField] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionShow(submissionId: number, includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<Submission> {
            return localVarFp.submissionShow(submissionId, includeField, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary download submissions csv
         * @param {Array<string>} [submissionTypes] 
         * @param {string} [xApiVersion] 
         * @param {string} [xWorkstation] 
         * @param {string} [xOboAccountId] 
         * @param {string} [xOboUserId] 
         * @param {string} [xAcrossAccounts] 
         * @param {string} [xUserRoleId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        submissionsDownloadIndex(submissionTypes?: Array<string>, xApiVersion?: string, xWorkstation?: string, xOboAccountId?: string, xOboUserId?: string, xAcrossAccounts?: string, xUserRoleId?: string, options?: any): AxiosPromise<void> {
            return localVarFp.submissionsDownloadIndex(submissionTypes, xApiVersion, xWorkstation, xOboAccountId, xOboUserId, xAcrossAccounts, xUserRoleId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for eventSubmissionCreate operation in SubmissionApi.
 * @export
 * @interface SubmissionApiEventSubmissionCreateRequest
 */
export interface SubmissionApiEventSubmissionCreateRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly eventId: number

    /**
     * 
     * @type {EventsSubmissionCreateRequestContract}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly eventsSubmissionCreateRequestContract: EventsSubmissionCreateRequestContract

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for eventSubmissionDelete operation in SubmissionApi.
 * @export
 * @interface SubmissionApiEventSubmissionDeleteRequest
 */
export interface SubmissionApiEventSubmissionDeleteRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly submissionId: number

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionDelete
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for eventSubmissionIndex operation in SubmissionApi.
 * @export
 * @interface SubmissionApiEventSubmissionIndexRequest
 */
export interface SubmissionApiEventSubmissionIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly search?: string

    /**
     * 
     * @type {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items'>

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly size?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly submissionState?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for eventSubmissionShow operation in SubmissionApi.
 * @export
 * @interface SubmissionApiEventSubmissionShowRequest
 */
export interface SubmissionApiEventSubmissionShowRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly submissionId: number

    /**
     * 
     * @type {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'issuer_entity' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'note' | 'zendesk_jwt'>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for eventSubmissionUpdate operation in SubmissionApi.
 * @export
 * @interface SubmissionApiEventSubmissionUpdateRequest
 */
export interface SubmissionApiEventSubmissionUpdateRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly eventId: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly submissionId: number

    /**
     * 
     * @type {EventsSubmissionUpdateRequestContract}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly eventsSubmissionUpdateRequestContract: EventsSubmissionUpdateRequestContract

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiEventSubmissionUpdate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for secondmarketSubmissionCreate operation in SubmissionApi.
 * @export
 * @interface SubmissionApiSecondmarketSubmissionCreateRequest
 */
export interface SubmissionApiSecondmarketSubmissionCreateRequest {
    /**
     * 
     * @type {SecondmarketSubmissionCreateRequestContract}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly secondmarketSubmissionCreateRequestContract: SecondmarketSubmissionCreateRequestContract

    /**
     * 
     * @type {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for secondmarketSubmissionIndex operation in SubmissionApi.
 * @export
 * @interface SubmissionApiSecondmarketSubmissionIndexRequest
 */
export interface SubmissionApiSecondmarketSubmissionIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly assetType?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly placedAt?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly receivedAt?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly publishedAt?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly accountType?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly side?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly submissionState?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly orderItemState?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly expirationDate?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly orderEntryType?: Array<string>

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly orderItemId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly createdByUserId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly routingBrokerId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly search?: string

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly sizeRangeStart?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly sizeRangeEnd?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly atsPool?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt' | 'type_totals'>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for secondmarketSubmissionShow operation in SubmissionApi.
 * @export
 * @interface SubmissionApiSecondmarketSubmissionShowRequest
 */
export interface SubmissionApiSecondmarketSubmissionShowRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly submissionId: number

    /**
     * 
     * @type {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSecondmarketSubmissionShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for submissionCreate operation in SubmissionApi.
 * @export
 * @interface SubmissionApiSubmissionCreateRequest
 */
export interface SubmissionApiSubmissionCreateRequest {
    /**
     * 
     * @type {SubmissionCreateRequestContract}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly submissionCreateRequestContract: SubmissionCreateRequestContract

    /**
     * 
     * @type {Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly includeField?: Array<'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'broker' | 'note'>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionCreate
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for submissionIndex operation in SubmissionApi.
 * @export
 * @interface SubmissionApiSubmissionIndexRequest
 */
export interface SubmissionApiSubmissionIndexRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly eventId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly accountId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly issuerEntityId?: number

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly assetType?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly placedAt?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly receivedAt?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly publishedAt?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly accountType?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly side?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly submissionState?: Array<string>

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly orderItemState?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly expirationDate?: string

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly orderEntryType?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly orderEntryCategory?: string

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly orderItemId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly createdByUserId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly routingBrokerId?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly size?: number

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly search?: string

    /**
     * 
     * @type {boolean}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly clientOrders?: boolean

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly sizeRangeStart?: number

    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly sizeRangeEnd?: number

    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly atsPool?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly sort?: string

    /**
     * 
     * @type {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionIndex
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for submissionShow operation in SubmissionApi.
 * @export
 * @interface SubmissionApiSubmissionShowRequest
 */
export interface SubmissionApiSubmissionShowRequest {
    /**
     * 
     * @type {number}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly submissionId: number

    /**
     * 
     * @type {Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly includeField?: Array<'note' | 'steps' | 'issuer_entity' | 'event' | 'account' | 'items' | 'user' | 'signed_by' | 'signed_by_spouse' | 'transition_checklist' | 'issuance' | 'settings' | 'order_settings' | 'assets' | 'group' | 'submission_statistics' | 'submission_statistics_total_count' | 'cap_table_statistics' | 'contact_information' | 'asset' | 'holding' | 'matches' | 'pct_premium_to_preferred_price' | 'implied_valuation' | 'broker' | 'user_counterparty_active_negotiation_id' | 'order' | 'issuer_entity_sectors' | 'user_account_watchlist_has_issuer' | 'issuer_entity_statistic' | 'zendesk_jwt'>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionShow
     */
    readonly xUserRoleId?: string
}

/**
 * Request parameters for submissionsDownloadIndex operation in SubmissionApi.
 * @export
 * @interface SubmissionApiSubmissionsDownloadIndexRequest
 */
export interface SubmissionApiSubmissionsDownloadIndexRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof SubmissionApiSubmissionsDownloadIndex
     */
    readonly submissionTypes?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionsDownloadIndex
     */
    readonly xApiVersion?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionsDownloadIndex
     */
    readonly xWorkstation?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionsDownloadIndex
     */
    readonly xOboAccountId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionsDownloadIndex
     */
    readonly xOboUserId?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionsDownloadIndex
     */
    readonly xAcrossAccounts?: string

    /**
     * 
     * @type {string}
     * @memberof SubmissionApiSubmissionsDownloadIndex
     */
    readonly xUserRoleId?: string
}

/**
 * SubmissionApi - object-oriented interface
 * @export
 * @class SubmissionApi
 * @extends {BaseAPI}
 */
export class SubmissionApi extends BaseAPI {
    /**
     * 
     * @summary create a submission for the specified event
     * @param {SubmissionApiEventSubmissionCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public eventSubmissionCreate(requestParameters: SubmissionApiEventSubmissionCreateRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).eventSubmissionCreate(requestParameters.eventId, requestParameters.eventsSubmissionCreateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary the specified submission
     * @param {SubmissionApiEventSubmissionDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public eventSubmissionDelete(requestParameters: SubmissionApiEventSubmissionDeleteRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).eventSubmissionDelete(requestParameters.eventId, requestParameters.submissionId, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list submissions for specified event
     * @param {SubmissionApiEventSubmissionIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public eventSubmissionIndex(requestParameters: SubmissionApiEventSubmissionIndexRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).eventSubmissionIndex(requestParameters.eventId, requestParameters.accountId, requestParameters.search, requestParameters.includeField, requestParameters.page, requestParameters.size, requestParameters.submissionState, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary the specified submission
     * @param {SubmissionApiEventSubmissionShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public eventSubmissionShow(requestParameters: SubmissionApiEventSubmissionShowRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).eventSubmissionShow(requestParameters.eventId, requestParameters.submissionId, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary the specified M&A submission
     * @param {SubmissionApiEventSubmissionUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public eventSubmissionUpdate(requestParameters: SubmissionApiEventSubmissionUpdateRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).eventSubmissionUpdate(requestParameters.eventId, requestParameters.submissionId, requestParameters.eventsSubmissionUpdateRequestContract, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an ioi or firm submission
     * @param {SubmissionApiSecondmarketSubmissionCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public secondmarketSubmissionCreate(requestParameters: SubmissionApiSecondmarketSubmissionCreateRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).secondmarketSubmissionCreate(requestParameters.secondmarketSubmissionCreateRequestContract, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list submissions based on filters
     * @param {SubmissionApiSecondmarketSubmissionIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public secondmarketSubmissionIndex(requestParameters: SubmissionApiSecondmarketSubmissionIndexRequest = {}, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).secondmarketSubmissionIndex(requestParameters.accountId, requestParameters.issuerEntityId, requestParameters.assetType, requestParameters.placedAt, requestParameters.receivedAt, requestParameters.publishedAt, requestParameters.accountType, requestParameters.side, requestParameters.submissionState, requestParameters.orderItemState, requestParameters.expirationDate, requestParameters.orderEntryType, requestParameters.orderItemId, requestParameters.createdByUserId, requestParameters.routingBrokerId, requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.sizeRangeStart, requestParameters.sizeRangeEnd, requestParameters.atsPool, requestParameters.sort, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show a submission
     * @param {SubmissionApiSecondmarketSubmissionShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public secondmarketSubmissionShow(requestParameters: SubmissionApiSecondmarketSubmissionShowRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).secondmarketSubmissionShow(requestParameters.submissionId, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary create an ioi or firm submission
     * @param {SubmissionApiSubmissionCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionCreate(requestParameters: SubmissionApiSubmissionCreateRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionCreate(requestParameters.submissionCreateRequestContract, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary list submissions based on filters
     * @param {SubmissionApiSubmissionIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionIndex(requestParameters: SubmissionApiSubmissionIndexRequest = {}, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionIndex(requestParameters.eventId, requestParameters.accountId, requestParameters.issuerEntityId, requestParameters.assetType, requestParameters.placedAt, requestParameters.receivedAt, requestParameters.publishedAt, requestParameters.accountType, requestParameters.side, requestParameters.submissionState, requestParameters.orderItemState, requestParameters.expirationDate, requestParameters.orderEntryType, requestParameters.orderEntryCategory, requestParameters.orderItemId, requestParameters.createdByUserId, requestParameters.routingBrokerId, requestParameters.page, requestParameters.size, requestParameters.search, requestParameters.clientOrders, requestParameters.sizeRangeStart, requestParameters.sizeRangeEnd, requestParameters.atsPool, requestParameters.sort, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary show a submission
     * @param {SubmissionApiSubmissionShowRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionShow(requestParameters: SubmissionApiSubmissionShowRequest, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionShow(requestParameters.submissionId, requestParameters.includeField, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary download submissions csv
     * @param {SubmissionApiSubmissionsDownloadIndexRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SubmissionApi
     */
    public submissionsDownloadIndex(requestParameters: SubmissionApiSubmissionsDownloadIndexRequest = {}, options?: AxiosRequestConfig) {
        return SubmissionApiFp(this.configuration).submissionsDownloadIndex(requestParameters.submissionTypes, requestParameters.xApiVersion, requestParameters.xWorkstation, requestParameters.xOboAccountId, requestParameters.xOboUserId, requestParameters.xAcrossAccounts, requestParameters.xUserRoleId, options).then((request) => request(this.axios, this.basePath));
    }
}

export type EventSubmissionCreateErrorTypes =

    | {
        status: 403;
    }

    | {
        status: 422;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventSubmissionCreateErrorCodes = [
    403,
    422,
    442,
];

export type EventSubmissionDeleteErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventSubmissionDeleteErrorCodes = [
    401,
    422,
];

export type EventSubmissionIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventSubmissionIndexErrorCodes = [
    401,
    442,
];

export type EventSubmissionShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 442;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventSubmissionShowErrorCodes = [
    401,
    442,
];

export type EventSubmissionUpdateErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: 422;
        data: ErrorValidation;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const EventSubmissionUpdateErrorCodes = [
    401,
    422,
];

export type SecondmarketSubmissionCreateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketSubmissionCreateErrorCodes = [
    422,
];

export type SecondmarketSubmissionIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketSubmissionIndexErrorCodes = [
    401,
];

export type SecondmarketSubmissionShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SecondmarketSubmissionShowErrorCodes = [
    401,
];

export type SubmissionCreateErrorTypes =

    | {
        status: 422;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SubmissionCreateErrorCodes = [
    422,
];

export type SubmissionIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SubmissionIndexErrorCodes = [
    401,
];

export type SubmissionShowErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SubmissionShowErrorCodes = [
    401,
];

export type SubmissionsDownloadIndexErrorTypes =

    | {
        status: 401;
        data: Error;
    }

    | {
        status: -1;
        originalStatus?: any | null | undefined;
        data?: any | null | undefined;
    };

export const SubmissionsDownloadIndexErrorCodes = [
    401,
];


