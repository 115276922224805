import {
  type AccountShowAggregate,
  CbEventStatus,
  useEventListLazy,
} from '@npm/data-access';

import { useCurrentWorkstation } from '../../auth/user/role';

export const useIsInvitedToLivePrograms = () => {
  const workstation = useCurrentWorkstation();
  const isBrokerageWS = workstation?.type?.code === 'brokerage';
  const [fetchEventList] = useEventListLazy();

  return async (account: AccountShowAggregate) => {
    try {
      const eventListData = await fetchEventList({
        variables: {
          accountId: account.id,
          eventStatus: CbEventStatus.items.ACTIVE,
          size: 0,
          // in BRO, need to specify OBO header values to be able to fetch list of events for a specific account:
          ...(isBrokerageWS && {
            xOboAccountId: account.id.toString(),
            xOboUserId: account.representative?.user_id?.toString(),
          }),
        },
      });

      return eventListData.pagination?.total_records > 0;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
};
